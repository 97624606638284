export const isTokenExpired = (token: string): boolean => {
  try {
    const { exp } = JSON.parse(window.atob(token.split('.')[1]));
    const expirationDate = new Date(exp * 1000);
    const bufferTime = 5 * 60 * 1000; // 5 minutes before expiration
    return Date.now() > expirationDate.getTime() - bufferTime;
  } catch (error) {
    console.error('Error checking token expiration:', error);
    return true;
  }
}