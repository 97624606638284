import { Outlet, useNavigate } from 'react-router-dom'
import { useState, React, useEffect } from 'react'
import { Menu } from 'semantic-ui-react'
import Footer from './Footer'
import '../components/NavMenu.css'

const NavMenu = () => {
    const menuArr = ['Analytics', 'Routed', 'FetchGoatNow', 'Logs', 'Preferences', 'Reports', 'Support', 'Tracking', 'RouteStopTemplates', 'Search']
    var index = menuArr.indexOf('RouteStopTemplates');
    if (index !== -1) {
        localStorage.courierId === "null" && menuArr.splice(index, 1);
        // console.log(localStorage)
        // localStorage.setItem("courierId", 30027);
    }
    const [activeItem, setActiveItem] = useState(localStorage.getItem("route"))
    const navigate = useNavigate();

    //drop down
    // const [selectedItem, setSelectedItem] = useState(null)

    // const handleSelectedItemChange = (e, { value }) => {
    //     setSelectedItem(value)
    // }

    //--------------------------------------------------------


    const handleItemClick = (e, { name }) => {
        let selectedMenuItem = name;
        if (selectedMenuItem.includes('Dashboard')) {
            var extension = "/Routed";
            selectedMenuItem = selectedMenuItem.concat(extension.toString())
        }

        localStorage.setItem("route", selectedMenuItem);

        navigate(`/${selectedMenuItem}`)
        selectedMenuItem.includes("Dashboard") ? setActiveItem("Dashboard") : setActiveItem(selectedMenuItem);
    }

    useEffect(() => {
        const loggedInUser = localStorage.getItem("authenticated");
        if (JSON.parse(loggedInUser) === false) {
            navigate('/')
        }
        setActiveItem(localStorage.route)

    }, [navigate]);


    const handleLogout = () => {
        localStorage.setItem("authenticated", false);
        localStorage.removeItem("token");
        localStorage.removeItem("courierId");
        navigate("/");
    }

    return (
        <>
            <div className='background'>
                <div className='header-container'>
                    <div className='header' >
                        <img src="/images/headerforLogin.jpg" alt="header" style={{ width: "100%" }} />
                    </div>
                    <Menu pointing inverted className="navMenu">
                        {menuArr.map((title, id) => {
                            return (
                                <Menu.Item
                                    key={id}
                                    name={title}
                                    active={activeItem === title.replace(/\s/g, '')}
                                    onClick={handleItemClick}
                                    className="navMenuItem"
                                />
                            )
                        })}

                        <Menu.Menu position='right'>
                            <Menu.Item
                                name='Log Out'
                                active={activeItem === 'Log Out'}
                                onClick={() => handleLogout('/')}
                            />
                        </Menu.Menu>
                    </Menu>
                </div>

                <Outlet />
                <Footer />
            </div>
        </>
    )
}

export default NavMenu

