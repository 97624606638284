import React, { useState, useContext, useEffect } from "react";
import { User, UserContextProps } from "../services/types";

export const AppContext = React.createContext<UserContextProps | undefined>(undefined);

export const useUser = () => {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
};

interface AppProviderProps {
    children: React.ReactNode
}

export const AppProvider = ({ children }: AppProviderProps ) => {
    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
        const storedUser = localStorage.getItem("user");
        if (storedUser) {
            setUser(JSON.parse(storedUser)); // Set the user from localStorage
        }
    }, []); // Only run this effect once, when the component mounts

    return <AppContext.Provider value={{ user, setUser }}>{children}</AppContext.Provider>;
};
