import { Segment } from "semantic-ui-react";

const size = 'mini'


const style = {
  segmentGroup: {
    'marginRight': '20px',
    'marginLeft': '20px',
    'marginTop': '20px',
    'marginBottom': '20px',
  },
  lateExceptionLess : {
    backgroundColor: "#FFF899",
    'paddingTop': '5px',
    'paddingBottom': '5px', 
    'fontWeight': 'bold',
  },
  lateExceptionBetween: {
    backgroundColor: "#FFE08C",
    'paddingTop': '5px',
    'paddingBottom': '5px', 
    'fontWeight': 'bold',
  },
  lateExceptionGreater: {
    backgroundColor:  "#FCC2C2",
    'paddingTop': '5px',
    'paddingBottom': '5px', 
    'fontWeight': 'bold',
  },
  missing: {
    backgroundColor:  "#C8B6FC",
    'paddingTop': '5px',
    'paddingBottom': '5px', 
    'fontWeight': 'bold',
  },
  missed: {
    backgroundColor:  "#C8B6FC",
    'paddingTop': '5px',
    'paddingBottom': '5px', 
    'fontWeight': 'bold',
  },
  early: {
    backgroundColor:  "#f5b642",
    'paddingTop': '5px',
    'paddingBottom': '5px', 
    'fontWeight': 'bold',
  },
  podMissing: {
    backgroundColor:  "#AFEEEE",
    'paddingTop': '5px',
    'paddingBottom': '5px', 
    'fontWeight': 'bold',
  },
  podDelay: {
    backgroundColor:  "#AFEEEE",
    'paddingTop': '5px',
    'paddingBottom': '5px', 
    'fontWeight': 'bold',
  }

}
const FilterRoutes = () => {
  return (
    <>
    <Segment.Group raised style={style.segmentGroup}>
   <Segment size={size} vertical textAlign='center' style={style.lateExceptionLess} >Late Exception Less than 60 Minutes</Segment>
   <Segment size={size} vertical textAlign='center' style={style.lateExceptionBetween}>Late Exception between 60 and 120 Minutes</Segment>
   <Segment size={size} vertical textAlign='center' style={style.lateExceptionGreater}>Late Exception greater than 120 Minutes</Segment>
   <Segment size={size} vertical textAlign='center' style={style.missing}>Missing</Segment>
   <Segment size={size} vertical textAlign='center' style={style.missed}>Missed</Segment>
   <Segment size={size} vertical textAlign='center' style={style.early}>Early</Segment>
   <Segment size={size} vertical textAlign='center' style={style.podMissing}>POD-Missing</Segment>
   <Segment size={size} vertical textAlign='center' style={style.podDelay}>POD-Delay</Segment>
   </Segment.Group>
    </>
  )

}

export default FilterRoutes;