import { useState } from "react";
import { Button, Form, Grid, Input, Modal } from 'semantic-ui-react';
import '../styles/Login.scss';
import { useNavigate } from 'react-router-dom'
import { accountService } from '../services';
import { RequestPayload } from '../services/ApiClient/types';
import { AuthenticateRequest } from "../services/AccountService/types";
import AzureMapsComponent from '../components/MapContainer';
import { useUser } from '../components/AppProvider';
import { User } from '../services/types';

const Login = () => {
  const navigate = useNavigate();
  const { setUser } = useUser();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoginError, setIsLoginError] = useState(false);
  const [loginError, setLoginError] = useState('Error');
  const [isLoading, setIsLoading] = useState(false);
  const loginsValid = password !== '' && username !== '';

  const handleSubmit = (e: any) => {
    let currentRoute: any = localStorage.getItem("route") === null ? "Routed" : localStorage.getItem("route");
    e.preventDefault();
    setIsLoading(true);

    let request: AuthenticateRequest = {
      username: username,
      password: password,
    };

    let payload: RequestPayload<AuthenticateRequest> = { payload: request };

    accountService.login(payload)
      .then(response => {
        localStorage.removeItem("courierId");
        response.success && storeToken();
        const token = response.result.jwtToken
        localStorage.setItem("token", token);
        let user: User = {
          userName: response.result.userPreferences.userName,
          userPreferences: response.result.userPreferences.userPreferences,
          userRestrictions: response.result.userPreferences.userRestrictions,
          applicationFeatures: response.result.userPreferences.applicationFeatures,
          courierId: response.result.courierId
        };
        const courierId = user.courierId ? user.courierId.toString() : null
        courierId === null && localStorage.setItem("route", 'Routed');
        localStorage.setItem("courierId", courierId); // Store user data in localStorage
        setUser(user);
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        setIsLoginError(true);
        password === '' && setLoginError('Password cannot be blank')
        username === '' && setLoginError('Username cannot be blank')
        if (loginsValid) {
          err.data === null && setLoginError(err.message);
          err.data !== null && setLoginError(err.data.message);
        }
      }
      );

    // This function stores the token and logs you in
    const storeToken = async () => {
      try {
        localStorage.setItem("authenticated", 'true');
        localStorage.setItem("route", currentRoute);
        navigate(`/${currentRoute}`)
      }
      catch (err) {
        console.log("err")
      }
    }


  };

  return (
    <div className='login-form'>
      <div className="grid_container">
        <Grid columns="1" style={{ height: '0px' }}>
          <Grid.Row>
            <Grid.Column xs={1}>
            </Grid.Column>
            <Grid.Column xs={8} style={{ display: 'flex', justifyContent: 'center' }} className="grid-column">
              <div className='fb-login-container'>
                <div className="fb-item fb-form">
                  <img className="logo" src='/Images/logo.png' alt="Logo" />
                  <Form className='form-login' onSubmit={handleSubmit}>
                    <Form.Field className="username">
                      <input placeholder='Username'
                        id='username'
                        value={username}
                        onChange={(event) => setUsername(event.target.value)} />
                    </Form.Field>
                    <Form.Field className="password">
                      <Input
                        type='password'
                        placeholder='Password'
                        id='password'
                        value={password}
                        onChange={(event) => setPassword(event.target.value)} />
                    </Form.Field>
                    <div id="login-buttons">
                      <Button disabled={isLoading} loading={isLoading} className="login" type='submit' color="blue">{!isLoading ? 'Log In' : 'Loading...'}</Button>
                      <Button className="forgotPass" onClick={() => navigate('forgotpassword')}>Forgot Password</Button>
                    </div>
                  </Form>
                </div>
                <div className="fb-item fb-map" style={{ width: '100vw', height: '80vh' }}>
                  <AzureMapsComponent />
                </div>
              </div>
            </Grid.Column>
            <Grid.Column xs={1}>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      <div className="login_photo_container">
        <img className="login_photo" src='/Images/login_picture.jpg' alt="Login" />
      </div>
      <Modal
        size={'mini'}
        open={isLoginError}
        dimmer={'blurring'}
        onClose={() => setIsLoginError(false)}
        closeIcon
      >
        <Modal.Header>Error Logging In</Modal.Header>
        <Modal.Content>{loginError}</Modal.Content>
      </Modal>
    </div>
  )
}

export default Login