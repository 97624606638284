    export const getOnTimeIcon = (onTime_icon: string) => {
        switch(onTime_icon.toLowerCase()) {
            case 'minus':
                return 'minus';
            case 'doubledown':
                return 'angle double down';
            case 'down':
                return 'angle down';
            case 'doubledp': 
                return 'angle double up';
            case 'dp':
            default:
                return 'angle up';
        }
    }

    export const getOnTimeColor = (onTime_color: string) => {
        switch(onTime_color.toLowerCase()) {
            case 'danger':
                return 'red';
            case 'success':
                return 'green'
            case 'info':
            default:
                return 'blue';
            
        }
    }