import { useEffect, useRef } from 'react';
import axios from 'axios';
import 'azure-maps-control/dist/atlas.min.css';
import { AuthenticationType } from 'react-azure-maps';
import { getConfig } from '../config';

const AzureMap = () => {
    const mapRef = useRef();
    const messagePanelRef = useRef();

    useEffect(() => {
        setTimeout(() => {
        }, 1000);
        let map, layer, timer;

        const urlTemplate = 'https://{azMapsDomain}/map/tile?api-version=2.0&tilesetId={tilesetId}&zoom={z}&x={x}&y={y}&timeStamp={timestamp}&tileSize=256&view=Auto';
        const weatherLayers = {
            'microsoft.weather.infrared.main': {
                interval: 10 * 60 * 1000,
                past: 3 * 60 * 60 * 1000,
            },
            'microsoft.weather.radar.main': {
                interval: 300000,
                past: 1.5 * 60 * 60 * 1000,
                future: 1.5 * 60 * 60 * 1000
            }
        };
        const displayMessages = [];

        const getMap = () => {
            map = new window.atlas.Map(mapRef.current, {
                center: [-95, 40],
                zoom: 3,
                view: 'Auto',
                authOptions: {
                    authType: AuthenticationType.subscriptionKey,
                    subscriptionKey: getConfig().options['azureMapsSubscriptionKey'],
                    getToken: function (resolve, reject) {
                        const tokenServiceUrl = "https://samples.azuremaps.com/api/GetAzureMapsToken";
                        axios.get(tokenServiceUrl)
                            .then(r => {
                                console.log("🚀 ~ file: MapContainer.js:45 ~ axios.get ~ r.data:", r.data);
                                resolve(r.data)
                            })
                            // added catch and added reject
                            .catch((error) => reject(console.log("Error", error)));
                    }
                }
            });

            map.events.add('ready', function () {
                loadWeatherLayer('microsoft.weather.radar.main');
            });
        };

        const loadWeatherLayer = (tilesetId) => {
            if (layer) {
                layer.stop();
                clearInterval(timer);
            }

            const now = new Date().getTime();
            const layerInfo = {
                interval: 1080000,
                past: 1.5 * 60 * 60 * 1000,
                future: 1.5 * 60 * 60 * 1000
            };
            const numTimestamps = (layerInfo.past + layerInfo.future) / layerInfo.interval;

            let tlOptions = [];
            // creates weather animated layer on map
            for (let i = 0; i < 3; i++) {
                const time = (now - layerInfo.past) + (i + 1) * layerInfo?.interval;
                tlOptions.push(createTileLayer(tilesetId, time));

                if (time === now) {
                    displayMessages.push('Current');
                } else {
                    const dt = (time - now) / 1000 / 60;
                    displayMessages.push(`${dt} minutes`);
                }
            }

            if (layer) {
                layer.setOptions({ tileLayerOptions: tlOptions });
                layer.play();
            }
            else {
                layer = new window.atlas.layer.AnimatedTileLayer({
                    tileLayerOptions: tlOptions,
                    duration: numTimestamps * 800,
                    autoPlay: true,
                    loop: true
                });

                // setTimeout(() => {
                map.events.add('onframe', layer.getPlayableAnimation(), () => {

                    // if (e.frameIdx >= 0) {
                    //     const msg = displayMessages[e.frameIdx];
                    //     // messagePanelRef.current.innerText = msg;
                    // }
                });
                map.layers.add(layer, 'labels');

                timer = setInterval(intervalHandler(tilesetId), layerInfo.interval);
                // }, 1000);


            }
        }

        const createTileLayer = (tilesetId, time) => {
            const timestamp = new Date(time)?.toISOString()?.slice(0, 19);
            return {
                tileUrl: urlTemplate.replace('{tilesetId}', tilesetId).replace('{timestamp}', timestamp),
                tileSize: 256,
                opacity: 0.9,
                maxSourceZoom: 15
            };
        };

        const intervalHandler = (tilesetId, i) => {
            const now = new Date().getTime();
            let tlOptions = [];

            return function () {
                const layerInfo = weatherLayers[tilesetId];
                const time = (now - layerInfo.past) + (i + 1) * layerInfo?.interval;
                // const timestamp = new Date(time).toISOString().slice(0, 19);
                // const layers = layer.getOptions().tileLayerOptions;
                tlOptions.shift();
                tlOptions.push(createTileLayer(tilesetId, time));
                layer.setOptions({ tileLayerOptions: tlOptions });
            }
        }
        getMap();
    }, []);

    return (
        <div>
            <div ref={mapRef} style={{ position: 'relative', width: '100%', minWidth: '290px', height: '440px' }}></div>
            <div ref={messagePanelRef} style={{ position: 'absolute', top: '20px', right: '20px', backgroundColor: 'white', padding: '2px', borderRadius: '15px', width: '110px', textAlign: 'center' }}></div>
        </div>
    );
};

export default AzureMap;