import { apiClient } from '../ApiClient';
import { TransactionLogView, TransactionLogFilterModel, RouteStopView } from "./types"
import { ApiResponse, RequestPayload } from "../ApiClient/types"



class LogService {
    /**
     * Returns filter model which is used to populate the filter search for the log page
     * @param payload = RequestPayload<null>
     * @example
     * ```ts
     * let payload: RequestPayload<null> = { payload: null };
     * ```
     * @returns TransactionLogFilterModel
     */
    async GetTransactionLogFilter(payload: RequestPayload<null>): Promise<ApiResponse<TransactionLogFilterModel>> {
        return apiClient.get<TransactionLogFilterModel> (
            '/logs/filter',
            payload
        );
    }

    /**
     * Retunrs list of RouteStops based on the file Id
     * @param payload 
     * @param queryParams.fid - string
     * @example
     * ```ts
     * let payload: RequestPayload<null> = { queryParams: { fid: '12345' }, payload: null };
     * ```
     * @returns RouteStopView[]
     */
    async GetStopHistory(payload: RequestPayload<null>): Promise<ApiResponse<RouteStopView[]>> {
        return apiClient.get<RouteStopView[]> (
            '/logs/stophistory',
            payload
        );
    }

    /**
     * Returns list of transaction logs
     * @param payload = RequestPayload<TransactionLogFilterModel>
     * @example
     * ```ts
     * let filter: TransactionLogFilterModel = { };
     * let payload: RequestPayload<TransactionLogFilterModel> = { payload: filter };
     * ```
     * @returns TransactionLogView[]
     */
    async GetFilteredLogs(payload: RequestPayload<TransactionLogFilterModel>): Promise<ApiResponse<TransactionLogView[]>> {
        return apiClient.post<TransactionLogView[]> (
            '/logs/',
            payload
        );
    }
}

export { LogService }
