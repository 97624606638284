import { Grid } from 'semantic-ui-react'
import MapDashboard from './MapDashboard'
import '../styles/RoutedMap.scss'
// TODO: finish logic for this import
// import { RoutesAccordian } from './RoutesAccordian'

type Props = {
    citiesArr: Array<any>,
    showWeather: boolean,
    postDate: Date
}

const RoutedMap = (props: Props) => {
    const { citiesArr, showWeather, postDate } = props;

    return (
        <Grid.Column width={13} computer={13} className='wholeMap'>
            <div className="db-item db-map map">
                <MapDashboard cities={citiesArr} showWeather={showWeather} postDate={postDate} />
            </div>
        </Grid.Column>
    )
}

export default RoutedMap