import _ from 'lodash';
import { Table, Icon, Pagination } from 'semantic-ui-react';
import "../styles/StopTable.scss";
import { formatAddress } from '../utils/addressHelper';
import { formatDateAndTimeOnTwoLines, formatTime, formatTimeRaw } from '../utils/datehelper';
import { RouteDetailsModelView, RouteStopView } from '../services/RoutesService/types';
import { getOnTimeColor, getOnTimeIcon } from '../utils/onTimeHelpers';
import StopDetails from './StopDetails';
import { useEffect, useState } from 'react';
import { RequestPayload } from '../services/ApiClient/types';
import { routeService } from '../services';
type Props = {
  tableData: RouteDetailsModelView;
}

const StopsTable = (props: Props) => {
  const { tableData } = props;
  console.log("tableData: ", tableData)

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage,] = useState(25);

  const style = {
    headerCell: {
      backgroundColor: "#2d8fc4",
      color: "white",
      border: "0.5px solid #ddd",
      fontSize: '1rem',
      textAlign: 'center',
      whiteSpace: 'pre-line'
    },
    tCell: {
      fontSize: '1rem',
      textAlign: 'center',
    },
    picStyle: {
      fontSize: '1rem',
      textAlign: 'center',
      display: 'tableCell',
      verticalAlign: 'middle'
      // display: 'flex',
      // flexDirection: 'column'
    },
    tCellLeft: {
      fontSize: '1rem',
      textAlign: 'left',
    },
  }
  let currentPageData = tableData?.routeStops.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Table unstackable sortable celled fixed id="table">
        <Table.Header id="th">
          <Table.Row >
            <Table.HeaderCell width={1} style={style.headerCell}>
              Reference
            </Table.HeaderCell>
            <Table.HeaderCell width={1} style={style.headerCell}>
              FG ID
            </Table.HeaderCell>
            <Table.HeaderCell width={1} style={style.headerCell}>
              Sequence
            </Table.HeaderCell>
            <Table.HeaderCell width={1} style={style.headerCell}>
              Address
            </Table.HeaderCell>
            <Table.HeaderCell width={1} style={style.headerCell}>
              Type
            </Table.HeaderCell>
            <Table.HeaderCell width={1} style={style.headerCell}>
              Time Min/ Pref/ Max
            </Table.HeaderCell>
            <Table.HeaderCell width={1} style={style.headerCell}>
              Arrival Time
            </Table.HeaderCell>
            <Table.HeaderCell width={1} style={style.headerCell}>
              Completion Time
            </Table.HeaderCell>
            <Table.HeaderCell width={1} style={style.headerCell}>
              Elapsed
            </Table.HeaderCell>
            <Table.HeaderCell width={1} style={style.headerCell}>
              Status
            </Table.HeaderCell>
            <Table.HeaderCell width={1} style={style.headerCell}>
              Exceptions<br />(Count - Codes)
            </Table.HeaderCell>
            <Table.HeaderCell width={1} style={style.headerCell}>
              Carrier
            </Table.HeaderCell>
            <Table.HeaderCell width={1} style={style.headerCell}>
              Prior 60 Days<br />On-time %
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {/* {!_.isEmpty(tableData) ? tableData.routeStops.map((row: RouteStopView, index: number) => { */}
          {!_.isEmpty(tableData) ? currentPageData.map((row: RouteStopView, index: number) => {
            let bgc;
            if (row.exceptionCountCodes) {
              if (row.exceptionCountCodes.includes("Missed")) { bgc = '#C8B6FC' }
              else if (row.exceptionCountCodes.includes("Late")) { bgc = '#FCC2C2' }
              else if (row.exceptionCountCodes.includes("Early")) { bgc = '#F5B642' }
              else bgc = '#fff'
            }
            return <Table.Row key={index} id="tr" style={{ backgroundColor: bgc, }}>
              <Table.Cell width={1} style={style.tCell}>{row.reference === "" ? 'No references' : row.reference}</Table.Cell>
              <Table.Cell width={1} style={style.picStyle}><StopDetails stopId={row.stopId} />{row.podSignatureUrl?.length > 0 ? <Icon name='picture' /> : null}</Table.Cell>
              <Table.Cell width={1} style={style.tCell}>{row.sequence}</Table.Cell>
              <Table.Cell width={1} style={style.tCellLeft}>{formatAddress(row.location, true)}</Table.Cell>
              <Table.Cell width={1} style={style.tCell}>{row.stopType}</Table.Cell>
              <Table.Cell width={1} style={style.tCell}>{formatTimeRaw(row.timeMin)} / {formatTimeRaw(row.timePref)} / {formatTimeRaw(row.timeMax)}</Table.Cell>
              <Table.Cell width={1} style={style.tCell}>{row.timeArrived === undefined ? '' : formatDateAndTimeOnTwoLines(row.timeArrived)}</Table.Cell>
              <Table.Cell width={1} style={style.tCell}>{row.timeCompleted === undefined ? '' : formatDateAndTimeOnTwoLines(row.timeCompleted)}</Table.Cell>
              <Table.Cell width={1} style={style.tCell}>{row.timeArrived !== undefined && row.timeCompleted !== undefined ? row.elapsedTime : row.timeArrived !== undefined && row.timeCompleted === undefined ? 'Now' : ''}</Table.Cell>
              <Table.Cell width={1} style={style.tCell}>{row.status}</Table.Cell>
              <Table.Cell width={1} style={style.tCellLeft}>{row.exceptionCountCodes}</Table.Cell>
              <Table.Cell width={1} style={style.tCell}>{row.courier}</Table.Cell>
              {/* <Table.Cell width={1} style={style.tCell}>{row?.stopExceptions[0]?.id}</Table.Cell> */}
              <Table.Cell width={1} style={style.tCell}><Icon color={getOnTimeColor(row.onTimeColor)} name={getOnTimeIcon(row.onTimeIcon)} />{row.sevenDayOnTimePercentage}%</Table.Cell>
            </Table.Row>
          }) : ""
          }
        </Table.Body>
      </Table>
      <Pagination
        activePage={currentPage}
        onPageChange={(e, activePage: any) => setCurrentPage(activePage.activePage)}
        siblingRange={1}
        pointing
        secondary
        totalPages={Math.ceil(tableData.routeStops.length / itemsPerPage)}
      />
    </div>

  )

}

export default StopsTable;

