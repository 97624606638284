import { useState, useReducer } from "react";
import "../styles/SearchTable.scss";
import { Table, Input, Icon, Form, Button } from 'semantic-ui-react';
import _ from 'lodash';
import { useNavigate, useLocation } from 'react-router-dom';
import ReactDatePicker from "react-datepicker";
import { formatTime } from "../utils/datehelper";

type StateType = {
    column: null | string;
    data: any[];
    direction: null | string;
}

type ActionType = {
    type: string;
    column: string;
};


function tableReducer(state: StateType, action: ActionType) {
    switch (action.type) {
        case 'CHANGE_SORT':
            if (state.column === action.column) {
                return {
                    ...state,
                    data: state.data.slice().reverse(),
                    direction: state.direction === 'ascending' ? 'descending' : 'ascending',
                }
            }
            return {
                column: action.column,
                data: _.sortBy(state.data, [action.column]),
                direction: 'ascending',
            }
        default:
            throw new Error()
    }
}

type Props = {
    setSelectedDate: (data: Date) => void;
    selectedDate: Date;
    tableData: any[];
    /**
     * A string list of column names that you'd like to exclude from the table
     */
    excludedColumns?: string[];
}
const SearchTable = (props: Props) => {

    const { setSelectedDate, selectedDate, tableData, excludedColumns } = props;
    const excluded: string[] = excludedColumns ?? ['onTime_icon', 'onTime_color', 'onTimeIcon', 'onTimeColor'];
    const location = useLocation()
    const navigate = useNavigate();
    const handleNavigate = (route: string) => {
        const navTo = `/${route}`
        navigate(navTo, {
            state: {
                preSetSelectedDate: selectedDate,
                preSetSelectedCarrier: RRI,
                routeDateNav: true,
                preSetValuesChanged: true,
                navFromCarrier: true,
                used: false,
                carrierCarrier: { Cname: Cname }
            }
        })
    }
    const [state, dispatch] = useReducer(tableReducer, {
        column: null,
        data: tableData,
        direction: null,
    })

    const { column, data, direction } = state;
    const [query, setQuery] = useState(location.state?.carrierCarrier ? location.state?.carrierCarrier.Cname : "");
    const headerArr = tableData !== null ? Object.keys(tableData[0]) : [];
    headerArr.forEach(header => {
        const newHeader = header.replace('_', ' ')
        const arr = newHeader.split(" ")
        for (var i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        const capHeader = arr.join(" ");
        headerArr.push(capHeader)
    });

    const search = (data: any[]) => {
        const keys = Object.keys(data[0]).filter((key: string) => !excluded.includes(key));
        return data.filter((item) => keys.some(key => item[key].toString().toLowerCase().includes(query.toLowerCase())));
    }

    const style = {
        headerCell: {
            backgroundColor: "#2d8fc4",
            color: "white",
            border: "0.5px solid #ddd",
            fontSize: '1rem',
            textAlign: 'center',
            whiteSpace: 'pre-line'
        },
        tCell: {
            fontSize: '1rem',
            textAlign: 'center',
        },
        nCell: {
            fontSize: '1rem',
            textAlign: 'center',
            cursor: 'pointer'
        }
    }

    const getOnTimeIcon = (onTime_icon: string) => {
        switch (onTime_icon) {
            case 'Minus':
                return 'minus';
            case 'DoubleDown':
                return 'angle double down';
            case 'Down':
                return 'angle down';
            case 'DoubleUp':
                return 'angle double up';
            case 'Up':
            default:
                return 'angle up';
        }
    }

    const getOnTimeColor = (onTime_color: string) => {
        switch (onTime_color) {
            case 'Danger':
                return 'red';
            case 'Success':
                return 'green'
            case 'Info':
            default:
                return 'blue';

        }
    }
    console.log('data', data)

    const Headers: any = () => {
        return (
            Object.keys(tableData[0])
                .filter((col: string) => !excluded.includes(col))
                .map((col, idx) => {
                    const newHeader = col.replace('_', ' ')
                    const arr = newHeader.split(" ")
                    for (var i = 0; i < arr.length; i++) {
                        if (arr[i] === 'exceptions') {
                            arr[i] = 'Exceptions \n(Count -Codes)'
                        }
                        else if (arr[i] === 'courier') {
                            arr[i] = "Carrier"
                        }
                        else if (arr[i] === 'courierId') {
                            arr[i] = "Carrier Id"
                        }
                        else if (arr[i] === 'courierName') {
                            arr[i] = "Carrier Name"
                        }
                        else if (arr[i] === 'today') {
                            arr[i] = "Today's"
                        }
                        else if (arr[i] === 'prior') {
                            arr[i] = 'Prior 60Days'
                        }
                        else if (arr[i] === 'onTime') {
                            arr[i] = '\nOn-time %'
                        }
                        else {
                            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
                        }
                    }
                    const capHeader = arr.join(" ").replace(/([A-Z])/g, ' $1').trim();
                    return (
                        <Table.HeaderCell
                            key={idx}
                            style={style.headerCell}
                            sorted={column === col && direction === 'ascending' ? 'ascending' : 'descending'}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: col })}
                        >
                            {capHeader}
                        </Table.HeaderCell>
                    )
                })
        )
    }

    let RRI: string;

    const selectedRoutes = (row: any) => {
        RRI = row.courier
        handleNavigate('Map')
    }

    var Cname: string;
    const selectedCName = (row: any) => {
        Cname = row.courierName
        handleNavigate('Dashboard')
    }
    return (
        <div className="app">
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <Form style={{ marginLeft: '2.5%', width: '43%', display: 'flex' }}>
                    <Form.Group>
                        <div id="date-picker" >
                            <ReactDatePicker selected={selectedDate} onChange={(date) => setSelectedDate(date)} />
                        </div>
                    </Form.Group>
                </Form>
                <Form >
                    <Form.Group>
                        <div >
                            <Input icon='search' placeholder={'Search...'} value={query} onChange={e => setQuery(e.target.value)} />
                        </div>
                    </Form.Group>
                </Form>
            </div>
            <div style={{ width: '100%', marginTop: '10px' }}>
                <Table sortable celled >
                    <Table.Header>
                        <Table.Row>
                            <Headers />
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {search(data)
                            .map((row: any, idx: number) => {
                                let bgc;
                                if (row.exceptions) {
                                    if (row.exceptions.includes("Missed")) { bgc = '#C8B6FC' }
                                    else if (row.exceptions.includes("Late")) { bgc = '#FCC2C2' }
                                    else if (row.exceptions.includes("Early")) { bgc = '#F5B642' }
                                    else bgc = '#fff'
                                }
                                if (row.topException_color) {
                                    bgc = row.topException_color;
                                }
                                if (row.topExceptionColor) {
                                    bgc = row.topExceptionColor;
                                }

                                if (row.courierId) {
                                    return (
                                        <Table.Row key={idx} style={{ backgroundColor: bgc, }}>
                                            {Object.keys(row)
                                                .filter((col: string) => !excluded.includes(col))
                                                .map((col, idx) => {
                                                    if (col === 'map') {
                                                        return (
                                                            <Table.Cell key={idx} style={style.tCell}>
                                                                <Icon size="large" color="blue" style={{ cursor: 'pointer' }} onClick={() => selectedRoutes(row)} name='marker' />
                                                            </Table.Cell>)
                                                    } else if (col === 'todayOnTime') {
                                                        return <Table.Cell key={idx} style={style.tCell}> {parseFloat(row[col].toString()).toFixed(2)}%</Table.Cell>
                                                    } else if (col === 'priorOnTime') {
                                                        return <Table.Cell key={idx} style={style.tCell}>
                                                            <Icon
                                                                color={getOnTimeColor(row['onTimeColor'])}
                                                                name={getOnTimeIcon(row['onTimeIcon'])}
                                                            /> {parseFloat(row[col].toString()).toFixed(2)}%</Table.Cell>
                                                    } else if (col === 'courierName') {
                                                        return <Table.Cell key={idx} style={style.nCell} onClick={() => selectedCName(row)}> {row[col].toString()}</Table.Cell>
                                                    }
                                                    else {
                                                        return <Table.Cell key={idx} style={style.tCell}> {row[col].toString()}</Table.Cell>
                                                    }
                                                })}
                                        </Table.Row>
                                    )
                                } else {
                                    return (
                                        <Table.Row key={idx} style={{ backgroundColor: bgc }}>
                                            {Object.keys(row)
                                                .filter((col: string) => !excluded.includes(col))
                                                .map((col, idx) => {
                                                    if (col === 'map') {
                                                        return <Table.Cell key={idx} style={style.tCell}> <Icon size="large" color="blue" style={{ cursor: 'pointer' }} onClick={() => selectedRoutes(row)} name='marker' /></Table.Cell>
                                                    }
                                                    else if (col === 'today_onTime') {
                                                        return <Table.Cell key={idx} style={style.tCell}> {parseFloat(row[col].toString()).toFixed(2)}%</Table.Cell>
                                                    }
                                                    else if (col === 'prior_onTime') {
                                                        return <Table.Cell key={idx} style={style.tCell}>
                                                            <Icon
                                                                color={getOnTimeColor(row['onTime_color'])}
                                                                name={getOnTimeIcon(row['onTime_icon'])}
                                                            /> {parseFloat(row[col].toString()).toFixed(2)}%</Table.Cell>
                                                    }
                                                    else if (col === 'start_time' || col === 'end_time') {
                                                        return <Table.Cell key={idx} style={style.tCell}> {formatTime(row[col].toString())}</Table.Cell>

                                                    }
                                                    else if (col === 'route_id') {
                                                        return <Table.Cell key={idx} style={style.tCell}>
                                                            <Button id="openButton" onClick={() => {
                                                                const navTo = `/Routed/${row.map}`
                                                                navigate(navTo, {
                                                                    state: {
                                                                        preSetSelectedDate: selectedDate
                                                                    }
                                                                })
                                                            }}>

                                                                {row.route_id}
                                                            </Button>
                                                        </Table.Cell>
                                                    }
                                                    else {
                                                        return <Table.Cell key={idx} style={style.tCell}> {row[col].toString()}</Table.Cell>
                                                    }
                                                })}
                                        </Table.Row>
                                    )
                                }
                            })}
                    </Table.Body>
                </Table>
            </div>
        </div >
    );
}
export default SearchTable;