import  React from 'react'
import { Location } from '../services/types'

export const formatAddress = (location: Location, includeName?: boolean): JSX.Element => {
  let name: string = includeName ? location.name : ''; 
  let line1: string = location.address1;
  let line2: string = location.address2;
  let line3: string = location.city + ', ' + location.state + ' ' + location.zip;
  
  let address: JSX.Element  = 
   line2 != null
   ? name != null 
    ? React.createElement('div', null, name, React.createElement("br"), line1, React.createElement("br"), line2, React.createElement("br"), line3)
    : React.createElement('div', null, line1, React.createElement("br"), line2, React.createElement("br"), line3)
    
   : name != null
    ? React.createElement('div', null, name, React.createElement("br"), line1, React.createElement("br"), line3)
    : React.createElement('div', null, line1, React.createElement("br"), line3)
  return address  
}