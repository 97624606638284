import { useEffect, useState, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { Step, Tab, Table, Icon, Button, Loader, Select, TableCell, Dropdown } from 'semantic-ui-react';
import SearchParcelTable from '../components/SearchParcelTable';
import SearchParcelTable2 from '../components/SearchParcelTable2';
import '../styles/Dashboard.scss';
import { parcelService, preferencesService } from '../services';
import { shipService } from '../services';
import { RequestPayload } from '../services/ApiClient/types';
import OnDemandSearch from '../components/OnDemandSearch';
import { IconSizeProp } from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon';
import { ShippingRequest, ShippingContact } from '../services/ShipService/types';
import Splash from '../components/SplashPage';

const initialSenderState: ShippingContact = {
    CompanyName: '',
    ContactName: '',
    Address: '',
    City: '',
    State: '',
    PostalCode: '',
    Country: '',
    Phone: ''
};

const initialRecipientState: ShippingContact = {
    CompanyName: '',
    ContactName: '',
    Address: '',
    City: '',
    State: '',
    PostalCode: '',
    Country: '',
    Phone: ''
};

const initialApiRequestState: ShippingRequest = {
    CustomerId: null,
    ServiceType: '',
    Sender: initialSenderState,
    Recipient: initialRecipientState,
    PickupDateFrom: '',
    PickupDateTo: '',
    DeliveryDateFrom: '',
    DeliveryDateTo: '',
    VehicleType: '',
    PackageType: '',
    PackageWeight: null,
    PackageLength: '',
    PackageWidth: '',
    PackageHeight: '',
    OrderDate: ''
};

function ApiRequestReducer(state: ShippingRequest, action: any) {
    switch (action.type) {
        case 'UPDATE_FIELD':
            const isInt = action.field === 'PackageWeight'
            const newVal = isInt ? parseInt(action.value) : action.value
            return {
                ...state,
                [action.field]: newVal,
            };
        case 'UPDATE_SENDER':
            return {
                ...state,
                Sender: {
                    ...state.Sender,
                    [action.field]: action.value,
                },
            };
        case 'UPDATE_RECIPIENT':
            return {
                ...state,
                Recipient: {
                    ...state.Recipient,
                    [action.field]: action.value,
                },
            };
        default:
            return state;
    }
};

const FetchGoatNow = () => {
    //The reducer hook to allow less state variables and cleaner code
    //This takes in a reducer function and the inital state for the dashboard
    const [apiRequestState, dispatchApiRequest] = useReducer(ApiRequestReducer, initialApiRequestState);
    const [carrierData, setCarrierData] = useState(null);
    const [parcelData, setParcelData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isActive, setIsActive] = useState(true);
    const [currentShipStep, setCurrentShipStep] = useState(1);
    const [cost, setCost] = useState(0);
    const [sCarrier, setSCarrier] = useState('');
    const [noResults, setNoResults] = useState(false);
    const [fgNow, setFgNow] = useState(false);

    const headerArr = ['Carrier', 'Cost', 'On Time', 'On Time (Estimated)', 'Select Carrier']
    const navigate = useNavigate();

    const handleInputChange = (e: any, data: any, isSender?: boolean) => {
        const { name, value } = data;
        let trimName = name.trim().split(' ')[0];
        if (trimName in apiRequestState.Sender && isSender) {
            dispatchApiRequest({ type: 'UPDATE_SENDER', field: trimName as keyof ShippingContact, value });
        } else if (trimName in apiRequestState.Recipient && !isSender) {
            dispatchApiRequest({ type: 'UPDATE_RECIPIENT', field: trimName as keyof ShippingContact, value });
        } else if (trimName in apiRequestState) {
            dispatchApiRequest({ type: 'UPDATE_FIELD', field: trimName as keyof ShippingRequest, value });
        }
    };

    useEffect(() => {
        if (localStorage.token === null || localStorage.token === undefined) {
            const navTo = `/`
            navigate(navTo)
        }
        else {
            localStorage.setItem('route', "FetchGoatNow")
            try {
                let payload: RequestPayload<null> = {
                    payload: null
                };
                getUserPref();
                setNoResults(false);
                parcelService.GetCarrierList(payload)
                    .then((response: any) => {
                        if (response.success) {
                            if (response.result !== (null || undefined)) {
                                response.result.carrierList.length > 0 ?
                                    setCarrierData(response.result.carrierList) :
                                    setNoResults(true);
                            }
                            else
                                setNoResults(true);
                            setLoading(false)
                        }
                        else {
                            setLoading(false)
                            console.error('Error pulling back route data', response.message);
                        }
                    });
            } catch (error: any) {
                console.log(error)
            }
        }
    }, [navigate]);


    //User Preferences
    const getUserPref = () => {
        let payload: RequestPayload<null> = { payload: null }
        preferencesService.GetUserPreferences(payload)
            .then(res => {
                const featuresArr = res.result.applicationFeatures;
                const hasFgNow = featuresArr.length > 0 && res.result.applicationFeatures[0]?.name === "FetchGoatNow";
                setFgNow(hasFgNow);
            })
    }

    let iconSize: IconSizeProp = "large";

    const handleCarrierInfo = (cost: number, CarrierName: string) => {
        setCost(cost);
        setSCarrier(CarrierName)
        setCurrentShipStep(prev => prev + 1)
    }
    const countryOptions = [
        { key: 'truck', value: 'truck', text: 'Truck - $10' },
        { key: 'Car', value: 'Car', text: 'Car - $8' },
        { key: 'Van', value: 'Van', text: 'Van - $18' },
    ]
    const styles = {
        tableCell: { display: 'flex', justifyContent: 'center', height: '70px' },
        tableData: { textAlign: 'center', verticalAlign: 'middle' },
        tableDataCarrier: { textAlign: 'left', verticalAlign: 'middle' },
        tableDropdown: { maxHeight: '34px', minHeight: '30px', paddingTop: '5px' },
        tableIcon: { marginLeft: '0', marginTop: '-2px' },
        tableBtn: { height: '38px', width: '63' }
    }
    const TestTable = () => {
        const { tableCell, tableData, tableDropdown, tableIcon, tableBtn, tableDataCarrier } = styles;
        return (
            <Table style={{ width: '90rem', margin: ' 2rem auto' }} celled striped>
                <Table.Header>
                    <Table.Row>
                        {headerArr.map((header: string, idx: number) => <Table.HeaderCell key={idx} textAlign='center' colSpan='1'>{header}</Table.HeaderCell>)}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <TableCell style={tableDataCarrier} collapsing>
                            <Icon name='vcard' /> DSA
                        </TableCell>
                        <TableCell style={tableData} textAlign='center'>
                            <Dropdown selection style={tableDropdown} placeholder='Select your Vehicle' options={countryOptions} />
                        </TableCell>
                        <TableCell style={tableData} collapsing textAlign='center'>
                            <b>95%</b>
                        </TableCell>
                        <TableCell style={tableData} textAlign='center'>
                            <b>98%</b>
                        </TableCell>
                        <TableCell style={tableCell} >
                            <Button style={tableBtn} color='blue' onClick={() => handleCarrierInfo(20.23, 'DSA')}><Icon style={tableIcon} size={iconSize} inverted name='long arrow alternate right' /></Button>
                        </TableCell>
                    </Table.Row>
                    <Table.Row>
                        <TableCell style={tableDataCarrier}>
                            <Icon name='shipping fast' /> Courier Logistics
                        </TableCell>
                        <TableCell style={tableData} textAlign='center'>
                            <b>$42.69</b>
                        </TableCell>
                        <TableCell style={tableData} textAlign='center'>
                            <b>98%</b>
                        </TableCell>
                        <TableCell style={tableData} textAlign='center'>
                            <b>93%</b>
                        </TableCell>
                        <TableCell style={tableCell} >
                            <Button style={tableBtn} color='blue' onClick={() => handleCarrierInfo(42.69, 'Courier Logistics')}><Icon style={tableIcon} size={iconSize} inverted name='long arrow alternate right' /></Button>
                        </TableCell>
                    </Table.Row>
                    <Table.Row>
                        <TableCell style={tableDataCarrier}>
                            <Icon name='ship' /> Atlas
                        </TableCell>
                        <TableCell style={tableData} textAlign='center'>
                            <b>$37.50</b>
                        </TableCell>
                        <TableCell style={tableData} textAlign='center'>
                            <b>96%</b>
                        </TableCell>
                        <TableCell style={tableData} textAlign='center'>
                            <b>99%</b>
                        </TableCell>
                        <TableCell style={tableCell}>
                            <Button style={tableBtn} color='blue' onClick={() => handleCarrierInfo(37.50, 'Atlas')}><Icon style={tableIcon} size={iconSize} inverted name='long arrow alternate right' /></Button>
                        </TableCell>
                    </Table.Row>
                    <Table.Row>
                        <TableCell style={tableDataCarrier}>
                            <Icon name='zip' /> Capital Couriers
                        </TableCell>
                        <TableCell style={tableData} textAlign='center'>
                            <b>$35.25</b>
                        </TableCell>
                        <TableCell style={tableData} textAlign='center'>
                            <b>96%</b>
                        </TableCell>
                        <TableCell style={tableData} textAlign='center'>
                            <b>97%</b>
                        </TableCell>
                        <TableCell style={tableCell}>
                            <Button style={tableBtn} color='blue' onClick={() => handleCarrierInfo(35.25, 'Capital Couriers')}><Icon style={tableIcon} size={iconSize} inverted name='long arrow alternate right' /></Button>
                        </TableCell>
                    </Table.Row>
                    <Table.Row>
                        <TableCell style={tableDataCarrier}>
                            <Icon name='shopping basket' /> Sterling Express
                        </TableCell>
                        <TableCell style={tableData} textAlign='center'>
                            <b>$46.87</b>
                        </TableCell>
                        <TableCell style={tableData} textAlign='center'>
                            <b>94%</b>
                        </TableCell>
                        <TableCell style={tableData} textAlign='center'>
                            <b>98%</b>
                        </TableCell>
                        <TableCell style={tableCell}>
                            <Button style={tableBtn} color='blue' onClick={() => handleCarrierInfo(46.87, 'Sterling Express')}><Icon style={tableIcon} size={iconSize} inverted name='long arrow alternate right' /></Button>
                        </TableCell>
                    </Table.Row>
                </Table.Body>
            </Table>
        )
    }

    const SubmitFGNow = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <h1>Thanks For Choosing FetchGoat</h1>
                <p>To place another order click <><b>here</b></></p>
                <Button onClick={() => setCurrentShipStep(1)} style={{ width: '18rem' }} basic color='green'>Order Details</Button>
            </div>
        )
    }

    const getCarrierParcels = (carrierId: number) => {
        try {
            // pull back the route data      
            let payload: RequestPayload<null> = { queryParams: { carrierId: carrierId }, payload: null }
            parcelService.GetParcelList(payload)
                .then(response => {
                    if (response.success) {
                        setParcelData(response.result.packageList)
                        // setParcelData(response.result.packageList.slice(31, 60))
                        setLoading(false)
                        setIsActive(false);
                    }
                    else {
                        setLoading(false)
                        console.error('Error pulling back route data', response.message);
                    }
                });
        } catch (error: any) {
            console.log(error)
        }
    }

    const trackingSteps = [
        {
            key: 'carrier',
            icon: 'truck',
            title: 'Carrier',
            description: 'Choose your carrier(s)',
            completed: !isActive,
            active: isActive,
            disabled: !isActive
        },
        {
            key: 'parcel',
            icon: 'gift',
            title: 'Parcel',
            description: 'Show Parcels by Carrier',
            active: !isActive,
            disabled: isActive
        },
    ]

    const shipSteps = [
        {
            key: 'create shipment',
            icon: 'box',
            title: 'Create Shipment',
            description: 'Create OnDemand Order',
            completed: currentShipStep > 1,
            active: currentShipStep === 1,
            disabled: currentShipStep !== 1
        },
        {
            key: 'select provider',
            icon: 'truck',
            title: 'Select Provider',
            description: 'Choose provider',
            completed: currentShipStep > 2,
            active: currentShipStep === 2,
            disabled: currentShipStep !== 2,
        },
        {
            key: 'Submit Order',
            icon: 'gift',
            title: 'Submit',
            description: 'Submit order',
            completed: currentShipStep > 3,
            active: currentShipStep === 3,
            disabled: currentShipStep !== 3
        },
    ]

    const shippingContact: ShippingContact = {
        CompanyName: 'From me',
        ContactName: 'Robert',
        Address: '60 hawkins store road',
        City: 'Kennesaw',
        State: 'GA',
        PostalCode: '30144',
        Country: 'US',
        Phone: '7701234567'
    }

    const recipientContact: ShippingContact = {
        CompanyName: 'To you',
        ContactName: 'Robert',
        Address: '65 hawkins store road',
        City: 'Kennesaw',
        State: 'GA',
        PostalCode: '30144',
        Country: 'US',
        Phone: '7701234567'
    }

    const updateTable = () => {
        let request: ShippingRequest = {
            CustomerId: 10002,
            ServiceType: 'STAT',
            Sender: shippingContact,
            Recipient: recipientContact,
            PickupDateFrom: '2023-08-01T08:00:00Z',
            PickupDateTo: '2023-08-01T13:00:00Z',
            DeliveryDateFrom: '2023-08-02T10:00:00Z',
            DeliveryDateTo: '2023-08-02T15:00:00Z',
            VehicleType: 'Car',
            PackageType: 'Box',
            PackageWeight: 2,
            PackageLength: '10',
            PackageWidth: '5',
            PackageHeight: '2',
            OrderDate: '2023-08-01T12:00:00Z'
        };

        let payload: RequestPayload<ShippingRequest> = { payload: request };
        try {
            setNoResults(false);
            shipService.GetCustomerQuotes(payload)
                .then(response => {
                    if (response.success) {
                        setLoading(false);
                    }
                    else {
                        setLoading(false);
                        console.error('Error pulling back route data', response.message);
                    }
                });
        } catch (error: any) {
            console.log(error)
        }
    }

    const goBack = () => {
        setIsActive(true)
    }

    const nextStep: any = (id: number) => {
        getCarrierParcels(id);
    }

    const panes = [
        //Tracking Pane
        {
            menuItem: { key: 'Tracking', icon: 'wpforms', content: 'Tracking' },
            render: () =>
            (
                <Tab.Pane style={{ minHeight: '70vh' }}>
                    <div id="route-box">
                        <div className="buttons-container">
                            <Step.Group items={trackingSteps} />
                        </div>
                        {loading ?
                            <div> <Loader active size='massive'>Loading</Loader></div>
                            :
                            noResults ?
                                <div><h1>User returned no results</h1></div>
                                :
                                isActive ?
                                    <SearchParcelTable tableData={carrierData} nextStep={nextStep} />
                                    :
                                    <SearchParcelTable2 tableData={parcelData} goBack={goBack} />
                            // <OnDemandSearch />
                        }
                    </div>
                </Tab.Pane>
            )
        },

        //Ship pane
        {
            menuItem: { key: 'Ship', icon: 'box', content: 'Ship' },
            render: () =>
            (
                <Tab.Pane style={{ minHeight: '70vh' }}>
                    <div className="buttons-container">
                        <Step.Group items={shipSteps} />
                    </div>

                    {currentShipStep <= 3 &&
                        <OnDemandSearch
                            handleInputChange={handleInputChange}
                            updateTable={updateTable}
                            selectedCarrier={sCarrier}
                            price={cost}
                            currentShipStep={currentShipStep}
                            setCurrentShipStep={setCurrentShipStep}
                        />
                    }
                    {currentShipStep === 2 && <TestTable />}
                    {currentShipStep === 4 && <SubmitFGNow />}
                </Tab.Pane>
            )
        },
    ]

    return (
        <div id="routes-container">
            {fgNow ? <Tab panes={panes} /> : <Splash />}
        </div>
    )
};

export default FetchGoatNow;
