import { useState, useEffect } from 'react';
import { Button, Grid, Icon, Form, Loader, Select, Dropdown } from 'semantic-ui-react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../styles/DashboardMap.scss';
import DatePicker from 'react-datepicker';
import { routeService } from '../services';
import { RequestPayload } from '../services/ApiClient/types';
import { statesArray } from '../utils/statesArray';
import { RouteInfo } from '../services/RoutesService/types';
import RoutedMap from '../components/RoutedMap';

type RouteOptions = {
  text: string,
  value: string
}

const exceptionOptions: Array<RouteOptions> = [
  { text: "All Routes", value: "all-routes" },
  { text: "All Exceptions", value: "all-exceptions" },
  { text: "Early", value: "early" },
  { text: "Missed Stop", value: "missed-stop" },
  { text: "Late", value: "late" },
  { text: "Out Of Sequence", value: "out-of-sequence" },
  { text: "Missing POD", value: "missing-pod" },
  { text: "Carrier Specific", value: "real" }
]

const dateOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: 'numeric'
};

const DashboardMap = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const [postDate, setPostDate] = useState(location.state === null || undefined ? new Date() : location.state?.preSetSelectedDateFromCarrier || location.state?.preSetSelectedDate);
  const [toggle, setToggle] = useState(true);
  const [filterStates, setFilterStates] = useState({ states: [], countries: [] });
  const [carriers, setCarriers] = useState([location?.state === null || undefined ? { key: 0, text: "All Carriers", value: "All Carriers" } : { key: 0, text: location.state?.preSetSelectedCarrier, value: location.state?.preSetSelectedCarrier }]);
  const [carrier, setCarrier] = useState(location.state?.preSetSelectedCarrier === null || undefined ? "All Carriers" : location.state?.preSetSelectedCarrier);
  const [exception, setException] = useState(exceptionOptions[0]);
  const [isLoading, setIsLoading] = useState(true);
  const [citiesArr, setCitiesArr] = useState([]);
  const [filteredCities, setfilteredCities] = useState(citiesArr);
  const [countrySelected, setCountrySelected] = useState(false)


  console.log(location.state && "location.state: ", location.state)
  const NavValues = () => {
    if (location.state?.routeDateNav) {
      setPostDate(location.state.preSetSelectedDate)
      location.state.routeDateNav = false
      return location.state.preSetSelectedDate.toLocaleDateString('en-US', dateOptions)
    } else {
      return postDate.toLocaleDateString('en-US', dateOptions)
    }
  }
  const getUniqueCountries = (routes: Array<RouteInfo>) => {
    const uniqueCountries = new Set();
    routes.forEach((route: any) => {
      if (route.stops && Array.isArray(route.stops)) {
        route.stops.forEach((stop: any) => {
          if (stop.stateReference) {
            uniqueCountries.add(stop.stateReference.key.slice(0, 3).trim().toUpperCase())
          }
        });
      }
    });
    return Array.from(uniqueCountries)
  }

  const getUniqueStates = (routes: Array<RouteInfo>) => {
    const uniqueStates = new Set();
    routes.forEach((route: any) => {
      if (route.stops && Array.isArray(route.stops)) {
        route.stops.forEach((stop: any) => {
          if (stop.state) {
            uniqueStates.add(stop.state.trim().toUpperCase());
          }
        });
      }
    });

    // Convert the Set to an array
    return Array.from(uniqueStates);
  }

  const getUniqueCarriers = (routes: Array<RouteInfo>) => {

    // Initialize an empty object to store unique courier names as keys
    const uniqueCourierNames: { [key: string]: any } = {};

    // Initialize an array to store the unique objects in the desired format
    const uniqueObjects: Array<any> = [{ key: 0, text: 'All Carriers', value: "All Carriers" }];

    // Iterate through the 'routes' array of objects
    routes.forEach((obj: RouteInfo) => {
      // Check if the courierName is not already in the uniqueCourierNames object
      if (!uniqueCourierNames[obj.courierName]) {
        // If not found, add it to the uniqueCourierNames object and to the uniqueObjects array in the desired format
        uniqueCourierNames[obj.courierName] = true;
        uniqueObjects.push({
          key: obj.courierName,
          text: obj.courierName,
          value: obj.courierName,
        });
      }
    });

    return uniqueObjects
  }

  const fetchData = async () => {
    setIsLoading(true);
    const citiesArr: any[] = [];

    try {
      let payload: RequestPayload<null> = { queryParams: { pDate: NavValues() }, payload: null };
      const response: any = await routeService.GetMapList(payload)
        .then(response => {
          if (response.success) {
            return response.result
          }
          else {
            console.error('Error pulling back route data', response.message);
          }
        })
        .catch((error: any) => console.log("Error", error));

      await response.forEach((route: any) => {
        route.stops.forEach((stop: any) => {
          const stopInfo: any = {
            routeId: route.id,
            stopId: stop.id,
            sequence: stop.sequence,
            addressName: stop.addressName,
            position: [
              stop.longitude,
              stop.latitude
            ],
            carrierName: route.courierName,
            stopTopException: stop.topException,
            stopHasException: stop.hasException,
            stopState: stop.state,
            description: route.description,
            stateCode: stop.stateReference.key
          };
          citiesArr.push(stopInfo);
        });
      });
      setCitiesArr(citiesArr);
      setCarriers(() => getUniqueCarriers(response));
      setFilterStates({ ...filterStates, states: getUniqueStates(response), countries: getUniqueCountries(response) });
      setCarrier(() => location.state?.preSetSelectedCarrier === null || location.state?.preSetSelectedCarrier === undefined ? "All Carriers" : location.state?.preSetSelectedCarrier);
      // setCarrier(() => "All Carriers");
      setException(() => exceptionOptions[0]);
      setIsLoading(false);
    }
    catch (e) {
      setIsLoading(false);
      console.log(e)
    }
  };

  const handleNavigate = (route: string) => {
    const navTo = `/${route}`
    navigate(navTo, {
      state: {
        preSetSelectedDateFromMapView: postDate
      }
    })
  }

  const handleDateChange = async (date: Date) => {
    setPostDate(date)
    setCountrySelected(false)
  }

  const handleStateChange = (e: any, data: any) => {
    setFilterStates({ ...filterStates, states: data.value })
  }
  const handleRemoveLabel = (e: any, data: any) => {
    //check if country
    if (data.value === "US-") {
      setCountrySelected(true);
    }
  }
  const weather = () => {
    setToggle(!toggle)
  }

  useEffect(() => {
    if (localStorage.token === null || localStorage.token === undefined) {
      const navTo = `/`
      navigate(navTo)
    }
    else {
      localStorage.setItem('route', "Routed")
      fetchData();
    }
    // eslint-disable-next-line
  }, [navigate, postDate]);

  useEffect(() => {
    const filteredCities = citiesArr.filter((city: any) => {
      // Filter by state
      if (!filterStates.states.includes(city.stopState)) {
        return false;
      }

      // Filter by carrier
      if (!carrier.includes('All Carriers') && !city.carrierName.includes(carrier)) {
        return false;
      }

      // Filter by exception
      if (exception.value.includes('all-routes')) {
        return true;
      } else if (exception.value.includes('all-exceptions')) {
        return city.stopHasException;
      } else {
        return city.stopHasException && city.stopTopException.code.includes(exception.value);
      }
    });

    setfilteredCities(filteredCities);
  }, [citiesArr, carrier, filterStates, exception, toggle]);

  return (
    <div className='dashboard-map-container'>
      <div className="buttons-container">
        <Button.Group size='big' widths='one'>
          <Button id="bttn-carrier" onClick={() => handleNavigate('Routed')}>
            <Icon name="truck" />Carrier View
          </Button>
          <Button id="bttn-routes" onClick={() => handleNavigate('Dashboard')}>
            <Icon name="road" />Routes View
          </Button>
          <Button id="bttn-map" onClick={() => handleNavigate('Map')}>
            <Icon name="map" />Map View <Loader size='mini'>Loading</Loader>
          </Button>
        </Button.Group>
      </div>
      <Grid>
        {/*  FILTERS HEADER  */}
        <Grid.Column width={2} computer={2} id="dashboard-map-gc">
          <div className="form-container">
            <Form className="dashboard-map-form">
              <Form.Group className='map-group' widths='equal'>
                {/* <Form.Field id="date-picker"> */}
                <Form.Field>
                  <div className='date-picker'>
                    <DatePicker selected={postDate} onChange={(selectedDate) => handleDateChange(selectedDate)} />
                    <Form.Checkbox className="dashboard-checkbox" label='Weather Radar' checked={toggle} onClick={() => weather()} />
                  </div>
                </Form.Field>
                <Select style={{ height: "1rem", margin: "1.6rem 1rem 0rem 1rem" }} placeholder={exception.text} value={exception.text} options={exceptionOptions} onChange={(e: any, selection: any) => setException({ text: e.target.innerText, value: selection.value })} />
                <Select style={{ height: "1rem", margin: "1.6rem 1rem 0rem 1rem" }} value={carrier} placeholder='All Carriers' options={carriers} onChange={(e: any, selection: any) => setCarrier(selection.value)} />
                <Dropdown
                  placeholder='States'
                  fluid
                  multiple
                  search
                  selection
                  options={statesArray}
                  className='dropdown-style'
                  onChange={(e, data) => handleStateChange(e, data)}
                  onLabelClick={(e, data) => handleRemoveLabel(e, data)}
                  value={filterStates.states.length > 0 ? countrySelected ? filterStates.states.sort() : filterStates.countries.sort() : []}
                />
                {/* <Select style={{ height: "1rem", margin: "1.6rem 1rem 0rem 1rem" }} value={carrier} placeholder='All Carriers' defaultValue={options[0].text} options={options} onChange={(e: any, selection: any) => setCarrier(selection.value)} /> */}
              </Form.Group>
            </Form>
          </div>
        </Grid.Column>

        {/* Routes card and Map */}
        {isLoading ?
          <Grid.Column>
            <div style={{ marginTop: '35rem' }}><span><Loader active size='massive'>Loading Map...</Loader></span></div>
          </Grid.Column>

          :
          <RoutedMap
            citiesArr={filteredCities}
            showWeather={toggle}
            postDate={postDate}
          />
        }
      </Grid>
    </div>
  )

}

export default DashboardMap
