import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Form, Icon, Loader } from 'semantic-ui-react'
import SearchTable from '../components/SearchTable'
import '../styles/OnDemandCarrier.scss'
import { routeService } from '../services'
import { RequestPayload } from '../services/ApiClient/types'
import ReactDatePicker from 'react-datepicker'
import { Route } from '../services/RoutesService/types';
import { formatDate } from '../utils/datehelper';
import FilterRoutes from '../components/FilterRoutes';

const Dashboard = () => {
  const location = useLocation()
  const [routeData, setRouteData] = useState<Route[]>(null);
  const [loading, setLoading] = useState(true);
  const [noResponse, setNoResponse] = useState(false);
  const [selectedDate, setSelectedDate] = useState(location.state === null || undefined ? new Date() : location.state?.preSetSelectedDateFromCarrier || location.state?.preSetSelectedDate || location.state?.preSetSelectedDateFromMapView);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    setNoResponse(false);
    setRouteData(null);

    if (localStorage.token === null || localStorage.token === undefined) {
      const navTo = `/`
      navigate(navTo, {
        state: {
          preSetSelectedDate: selectedDate
        }
      })
    }
    else {
      localStorage.setItem('route', "Routed")
      try {
        let payload: RequestPayload<null> = {
          queryParams: {
            pDate: formatDate(selectedDate),
            searchBy: '',
            searchFor: ''
          },
          payload: null
        };

        routeService.GetRouteList(payload)
          .then(response => {
            if (response.success) {
              if (response.result.length === 0) setNoResponse(true)
              else {
                setNoResponse(false)
                setRouteData(() => response.result);
              }
              setLoading(false);
            }
            else {
              setLoading(false);
            }
          });
      } catch (err) {
        console.log(err)
        setLoading(false);
      }
    }
  }, [selectedDate, navigate]);

  const handleNavigate = (route: string) => {
    const navTo = `/${route}`
    navigate(navTo, {
      state: {
        preSetSelectedDate: selectedDate
      }
    })
  }

  return (
    <div id="routes-container">
      <div id="route-box">
        <div className="buttons-container">
          <Button.Group size='big'>
            <Button id="btn-carrier-view" onClick={() => handleNavigate('Routed')}>
              <Icon name="truck" />Carrier View
            </Button>
            <Button id="btn-routes-view" onClick={() => handleNavigate('Dashboard')}>
              <Icon name="road" />Routes View
            </Button>
            <Button id="btn-map-view" onClick={() => handleNavigate('Map')}>
              <Icon name="map" />Map View
            </Button>
          </Button.Group>
        </div>
        {loading && <div><Loader active size='massive'>Loading</Loader></div>}
        {noResponse && <div>
          <Form>
            <Form.Group>
              <div id="date-picker" style={{ marginLeft: '50px' }}>
                <ReactDatePicker selected={selectedDate} onChange={(date) => setSelectedDate(date)} />
              </div>
            </Form.Group>
          </Form>
          <h1>No Data Routes For Selected Date</h1></div>}
        {!loading && !noResponse && routeData &&
          <SearchTable setSelectedDate={setSelectedDate} selectedDate={selectedDate} tableData={routeData} excludedColumns={['onTime_icon', 'onTime_color', 'topException_color']} />
        }
      </div>
      <FilterRoutes />
    </div>
  )
}
export default Dashboard