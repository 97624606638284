import { apiClient } from '../ApiClient';
import { ReportSettings }  from './types';
import { ApiResponse, RequestPayload, RequestConfig } from "../ApiClient/types"

class ReportsService {
    /**
     * Returns report settings for the particular customer
     * @param payload  = RequestPayload<null> 
     * @example
     * ```ts
     * let payload: RequestPayload<null> = { payload: null };
     * ```
     * @returns ReportSettings
     */ 
    async GetReportSettings(payload: RequestPayload<null>): Promise<ApiResponse<ReportSettings>> {
      return apiClient.get<ReportSettings> (
        '/reports/',
        payload
      );
    }

    /**
     * Downloads specified report
     * @param payload  = RequestPayload<null> 
     * @param queryParams = ReportsQueryParams
     * @example
     * ```ts
     * let query: ReportsQueryParams = {
     *   from: '09/06/2023',
     *   to: '09/07/2023',
     *   companyId: 100002,
     *   reportStyle: ReportStyle.Audit_Miles_Stops_Summary 
     * }
     * let payload: RequestPayload<null> = { queryParams: query, payload: null };
     * ```
     * @returns ReportSettings
     */ 
    async DownloadReport(payload: RequestPayload<null>): Promise<ApiResponse<Blob>> {
      let responseType = 'blob';
      let config: RequestConfig = {
        responseType: responseType as ResponseType
      }
      return apiClient.get<Blob> (
        '/reports/download',
        payload,
        config
      );
    }

}

export { ReportsService }