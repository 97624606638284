import React, { useEffect, useReducer } from 'react'
import { Card, Grid, Button, Form, Input } from 'semantic-ui-react';
import '../styles/Reports.scss';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from 'react-router-dom';
import { reportsService } from '../services';
import { ReportsQueryParams, ReportSettings } from '../services/ReportsService/types';
import { RequestPayload } from '../services/ApiClient/types';
import { setToLocalTime, formatDate } from '../utils/datehelper';
const Reports = () => {

  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.token === null || localStorage.token === undefined) {
      const navTo = `/`
      navigate(navTo)
    }
    else {
      localStorage.setItem('route', "Reports")
      getReportSettings();
    }
  }, [navigate]);

  const [reportSettings, setReportSettings] = useReducer(
    (state: ReportSettings, newState: Partial<ReportSettings>) => ({
      ...state,
      ...newState,
    }),
    {
      from: new Date(),
      to: new Date(),
      companyId: 0,
    }
  );

  const handleStartDate = (date: Date) => {
    date > reportSettings.to && setReportSettings({ to: date });
    setReportSettings({ from: date });
  }

  const handleEndDate = (date: Date) => {
    date < reportSettings.from && setReportSettings({ from: date });
    setReportSettings({ to: date })
  }

  const getReportSettings = async () => {
    let payload: RequestPayload<null> = { payload: null };
    await reportsService.GetReportSettings(payload)
      .then(response => {
        if (response.success) {
          setReportSettings(response.result)
        }
        else {
          console.error("Error getting report settings", response.message);
        }
      }).catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getReportSettings();
  }, [setReportSettings]);

  const downloadReport = async () => {
    let query: ReportsQueryParams = {
      from: formatDate(reportSettings.from),
      to: formatDate(reportSettings.to),
      companyId: reportSettings.companyId,
      reportStyle: reportSettings.reportStyle
    }
    let payload: RequestPayload<null> = { queryParams: query, payload: null };
    await reportsService.DownloadReport(payload)
      .then(response => {
        const blob = new Blob([response.result], { type: response.result.type });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;

        let filename = response.message;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }).catch((error) => {
        console.log(error);
      });
  }

  const sortedCompanies = reportSettings.companies
    ? Object.entries(reportSettings.companies).sort(([, a], [, b]) => a.localeCompare(b))
    : null;

  const companyOptions = [];

  if (sortedCompanies && sortedCompanies.length > 0) {
    companyOptions.push({ key: 0, text: 'All (Default)', value: 0 })
  }

  if (companyOptions.length > 0) {
    sortedCompanies.map(([key, value]: any) => companyOptions.push({ key: key, text: value, value: key }))
  }

  const reportOptions = reportSettings.reportStyles
    ? Object.entries(reportSettings.reportStyles).map(([key, value]: any) =>
      ({ key: key, text: value, value: key }))
    : null;

  return (
    <div className="reports-container">
      <div className='reports-wrapper'>
        <Card className='reports-card'>
          <Card.Content style={{ backgroundColor: "#f5f5f5" }}>
            <Grid>
              <Grid.Row columns={2} style={{ display: 'flex', alignItems: 'center' }}>
                <Grid.Column width={5}>
                  <span style={{ fontSize: '18px' }}>Run Report</span>
                </Grid.Column>
                <Grid.Column width={11}>
                  <Button disabled={reportSettings.reportStyle === null || reportSettings.reportStyle === undefined} onClick={() => downloadReport()} primary color='blue' style={{ minWidth: '200px', float: 'right' }}>
                    Generate Report
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Card.Content>
          <Card.Content>
            <Form>
              <Form.Group>
                <Form.Field
                  control={Input}
                  label='From Date'
                  placeholder='MM/DD/YYYY'
                >
                  <DatePicker selected={setToLocalTime(reportSettings.from)} onChange={(date) => handleStartDate(date)} />
                </Form.Field>
                <Form.Field
                  control={Input}
                  label='To Date'
                  placeholder='MM/DD/YYYY'
                >
                  <DatePicker selected={setToLocalTime(reportSettings.to)} onChange={(date) => handleEndDate(date)} />
                </Form.Field>
              </Form.Group>
              {reportOptions &&
                <Form.Select
                  label='Report Style'
                  placeholder='Select Report'
                  value={reportSettings.reportStyle}
                  onChange={(e: any, data: any) => { setReportSettings({ reportStyle: data.value }) }}
                  options={reportOptions} />
              }

              {companyOptions.length > 0 &&
                <Form.Select
                  label='Company'
                  value={reportSettings.companyId}
                  onChange={(e: any, data: any) => { setReportSettings({ companyId: data.value }) }}
                  options={companyOptions}
                />
              }
            </Form>
          </Card.Content>
        </Card>

      </div>
    </div>

  )
}

export default Reports