import React, { useEffect, useState, ChangeEvent } from 'react';
import { Button, Form, TextArea, Icon, Modal } from 'semantic-ui-react';
import '../styles/Support.scss'
import { useNavigate } from 'react-router-dom';
import { SupportForm } from '../services/SupportService/types';
import { RequestPayload } from '../services/ApiClient/types';
import { supportService } from '../services';

type SupportObj = {
  key: string,
  text: string,
  value: string
}

const Support = () => {
  const options: Array<SupportObj> = [
    { key: 'Analytics', text: 'Analytics', value: 'Analytics' },
    { key: 'Reports', text: 'Reports', value: 'Reports' },
    { key: 'Routes', text: 'Routes', value: 'Routes' },
    { key: 'Route Stop', text: 'Route Stops', value: 'Route Stops' },
    { key: 'Rate Auditing ', text: 'Rate Auditing', value: 'Rate Auditing' },
    { key: 'Other', text: 'Other', value: 'Other' },
  ]

  const priority: Array<SupportObj> = [
    { key: 'Low', text: 'Low', value: 'Low' },
    { key: 'Medium', text: 'Medium', value: 'Medium' },
    { key: 'High', text: 'High', value: 'High' },
  ]

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [supportTopic, setSupportTopic] = useState('');
  const [supportPriority, setSupportPriority] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [subModal, setSubModal] = useState(false)
  const [subSuccess, setSubSuccess] = useState({ subval: false, errorMsg: null })
  const [fileList, setFileList] = useState<FileList | null>(null);

  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.token === null || localStorage.token === undefined) {
      const navTo = `/`
      navigate(navTo)
    }
    localStorage.setItem('route', "Support")
  }, [navigate]);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFileList(e.target.files);
  };
  const resetModal = () => {
    setSubModal(false)
    setSubSuccess({ subval: false, errorMsg: null })
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();

    let files: File[] = [];
    if (fileList != null && fileList.length > 0) {
      [].forEach.call(fileList, (file: any) => files.push(file));
    }

    let submissionData: SupportForm = {
      Name: name,
      EmailAddress: email,
      Topic: supportTopic,
      Subject: subject,
      Priority: supportPriority === 'Low' ? 1 : supportPriority === 'Medium' ? 2 : 3,
      Message: message,
      Files: files
    }
    let payload: RequestPayload<SupportForm> = { payload: submissionData };
    supportService.Submit(payload)
      .then(response => {
        if (response.success) {
          //display confirmation
          setSubModal(true)
        }
        else {
          console.error("Error submitting request", response.message);
          setSubModal(true)
          setSubSuccess({ subval: true, errorMsg: response.message })
        }
      })
      .catch(err => {
        console.log("err", err)
      })

  }
  return (
    <>
      <Modal
        dimmer={'blurring'}
        open={subModal}
        size='tiny'
      >
        <Modal.Header>{subSuccess.subval ? "There was an Error:" : "Thank you for contacting us today"}</Modal.Header>
        <Modal.Content>
          {subSuccess.subval ? "An Error has occurred while trying to compleat the task: " : "One of our customer support representatives will be in touch with you shortly."}
        </Modal.Content>
        <Modal.Actions>
          {subSuccess.subval ? <Button onClick={() => resetModal()} color='blue'>Review and retry</Button> : <Button onClick={() => resetModal()} color='green'>Continue</Button>}

        </Modal.Actions>
      </Modal>
      <div className='support-wrapper'>
        <div className="support-div">
          <p className="support-header">Create a support ticket</p>
          <Form onSubmit={handleSubmit} className="support-card" >
            <Form.Group widths='equal'>
              <Form.Input fluid label='Your Name' value={name} onChange={(e) => setName(e.target.value)} placeholder='Name' />
              <Form.Input fluid label='Your Email Address' value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Email Address' />
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Select
                value={supportTopic}
                onChange={e => setSupportTopic((e.target as HTMLInputElement).value)}
                fluid
                label="Topic *"
                options={options}
                placeholder="---Select Topic---">
              </Form.Select>
              <Form.Select
                value={supportPriority}
                onChange={e => setSupportPriority((e.target as HTMLInputElement).value)}
                fluid
                label="Priority *"
                options={priority}
                placeholder="---Select Priority---">
              </Form.Select>
              <Form.Field>
                <label>Attach Files</label>
                <input type="file" onChange={handleFileChange} multiple />
              </Form.Field>
            </Form.Group>
            <Form.Input fluid label='Subject *' value={subject} onChange={(e) => setSubject(e.target.value)} placeholder='Subject' />
            <Form.Field
              control={TextArea}
              label='Message Details'
              value={message}
              onChange={(e: any) => setMessage(e.target.value)}
              placeholder='Please describe your problem in details'
            />
            <Button type='submit' primary color='blue' className="support-button"><Icon name="envelope" />Send Message</Button>
          </Form>
        </div>

      </div>
    </>
  )
}

export default Support