import React, { useEffect, useRef } from 'react';
import axios from 'axios';
import 'azure-maps-control/dist/atlas.min.css';
import { AuthenticationType } from 'react-azure-maps';
import { getConfig } from '../config';
import { useNavigate } from 'react-router-dom';

const MapDashboard = (props) => {
  const mapRef = useRef();

  const navigate = useNavigate();

  const handleNavigate = (routeId) => {
    const navTo = `/Routed/${routeId}`
    navigate(navTo, {
      state: {
        showRouteModal: true,
        preSetSelectedDateFromMapView: props.postDate
      }
    })
  }

  /* TODO: replace handleNavigate with this to open new tab. Routed/:routedId will need to listen for this change
  const handleNavigate = (routeId) => {
    const navTo = `/Routed/${routeId}`;

    const stateToPass = {
        showRouteModal: true,
        preSetSelectedDateFromMapView: props.postDate
    };

    // Store the state in sessionStorage
    sessionStorage.setItem('routeState', JSON.stringify(stateToPass));

    // Open in a new tab
    const newWindow = window.open(navTo, '_blank');
    if (newWindow) {
        newWindow.opener = null;  // Secure the new tab from having a reference back to the opening tab.
    }
};
  */

  useEffect(() => {
    let map, layer, timer;
    const urlTemplate = 'https://{azMapsDomain}/map/tile?api-version=2.0&tilesetId={tilesetId}&zoom={z}&x={x}&y={y}&timeStamp={timestamp}&tileSize=256&view=Auto';
    const weatherLayers = {
      'microsoft.weather.infrared.main': {
        interval: 10 * 60 * 1000,
        past: 3 * 60 * 60 * 1000,
        future: 0
      },
      'microsoft.weather.radar.main': {
        interval: 5 * 60 * 1000,
        past: 1.5 * 60 * 60 * 1000,
        future: 1.5 * 60 * 60 * 1000
      }
    };
    const displayMessages = [];

    const getMap = () => {
      map = new window.atlas.Map(mapRef.current, {
        center: [-95, 40],
        zoom: 3,
        view: 'Auto',
        authOptions: {
          authType: AuthenticationType.subscriptionKey,
          subscriptionKey: getConfig().options['azureMapsSubscriptionKey'],
          getToken: function (resolve, reject) {
            const tokenServiceUrl = "https://samples.azuremaps.com/api/GetAzureMapsToken";
            axios.get(tokenServiceUrl)
              .then(r => {
                console.log("🚀 ~ file: MapContainer.js:45 ~ axios.get ~ r.data:", r.data);
                resolve(r.data);
              })
              // added in catch and resolve
              .catch(
                reject((error) => console.log("Error", error))
              )
          }
        }
      });


      // Step 1: Calculate how many markers are at each position.
      const offsetAmount = 500;  // Adjust as needed
      const positionCounts = {};
      const positionIndices = {};
      // console.log(props.cities)

      props.cities.forEach(city => {
        if (isNaN(city.position[0]) || isNaN(city.position[1])) {
          console.error('Invalid original position for city:', city);
        }
      });

      // Step 2: Offset markers at the same position.
      var markers = props.cities.map(city => {
        const key = `${city.position[0]},${city.position[1]}`;
        positionIndices[key] = (positionIndices[key] || 0) + 1;

        let offsetFactor = positionIndices[key] - 1; // So that the first marker doesn't get offset
        let markerPosition = [...city.position];
        if (positionCounts[key] > 1) {
          markerPosition[0] += offsetAmount * offsetFactor;
        }

        if (isNaN(markerPosition[0]) || isNaN(markerPosition[1])) {
          console.error('Invalid offset position for city:', city, 'Offset factor:', offsetFactor);
        }

        var marker = new window.atlas.HtmlMarker({
          position: markerPosition,
          htmlContent: `<svg width="15" height="36" viewBox="0 0 24 36" xmlns="http://www.w3.org/2000/svg"><path d="M12 0C7.6 0 4 3.6 4 8c0 6.4 8 20 8 20s8-13.6 8-20c0-4.4-3.6-8-8-8zm0 12c-1.2 0-2.2-1-2.2-2.2s1-2.2 2.2-2.2 2.2 1 2.2 2.2-1 2.2-2.2 2.2z" fill=${city.stopHasException ? city.stopTopException.color : 'DodgerBlue'} stroke="black"/></svg>`,
          popup: new window.atlas.Popup({
            content: city.stopHasException ?
              `<div style="padding:10px"> Route: ${city.description}  <br/>Location:  ${city.addressName} <br/> Exception: <b> ${city.stopTopException.description} </b><br /></div>`
              :
              `<div style="padding:10px"> Route: ${city.description}  <br/>Location:  ${city.addressName} <br/></div>`,
            pixelOffset: [0, -30],
          })
        });

        map.events.add('mouseenter', marker, () => marker.togglePopup());

        map.events.add('mouseleave', marker, () => marker.togglePopup());

        map.events.add('click', marker, () => handleNavigate(city.routeId));

        return marker;
      });

      // Add all markers to the map
      markers.forEach((marker) => {
        map.markers.add(marker);
      });

      map.events.add('ready', function () {
        props.showWeather && loadWeatherLayer('microsoft.weather.radar.main');
      });
    };

    const loadWeatherLayer = (tilesetId) => {
      if (layer) {
        layer.stop();
        clearInterval(timer);
      }

      const now = new Date().getTime();
      // const layerInfo = weatherLayers[tilesetId];
      const layerInfo = {
        interval: 1080000,
        past: 1.5 * 60 * 60 * 1000,
        future: 1.5 * 60 * 60 * 1000
      };
      const numTimestamps = (layerInfo.past + layerInfo.future) / layerInfo.interval;

      let tlOptions = [];
      // changed from numTimestamps to 3
      for (let i = 0; i < 3; i++) {
        const time = (now - layerInfo.past) + (i + 1) * layerInfo.interval;
        tlOptions.push(createTileLayer(tilesetId, time));

        if (time === now) {
          displayMessages.push('Current');
        } else {
          const dt = (time - now) / 1000 / 60;
          displayMessages.push(`${dt} minutes`);
        }
      }

      if (layer) {
        layer.setOptions({ tileLayerOptions: tlOptions });
        layer.play();
      } else {
        layer = new window.atlas.layer.AnimatedTileLayer({
          tileLayerOptions: tlOptions,
          duration: numTimestamps * 800,
          autoPlay: true,
          loop: true
        });
        map.layers.add(layer, 'labels');

        timer = setInterval(intervalHandler(tilesetId), layerInfo.interval);
      }
    }

    const createTileLayer = (tilesetId, time) => {
      const timestamp = new Date(time).toISOString().slice(0, 19);
      return {
        tileUrl: urlTemplate.replace('{tilesetId}', tilesetId).replace('{timestamp}', timestamp),
        tileSize: 256,
        opacity: 0.9,
        maxSourceZoom: 15
      };
    };

    const intervalHandler = (tilesetId, i) => {
      const now = new Date().getTime();
      let tlOptions = [];

      return function () {
        const layerInfo = weatherLayers[tilesetId];
        const time = (now - layerInfo.past) + (i + 1) * layerInfo.interval;
        tlOptions.shift();
        tlOptions.push(createTileLayer(tilesetId, time));
        layer.setOptions({ tileLayerOptions: tlOptions });
      }
    }

    getMap();
    // eslint-disable-next-line
  }, [props]);


  return (
    <div style={{ height: '100%' }}>
      <div ref={mapRef} style={{ position: 'relative', width: '100%', minWidth: '290px', height: '100%' }}></div>
    </div>
  );
};

export default MapDashboard;