import React from 'react';
import '../styles/Splash.scss'

const Splash = () => {
 
  return (
    <div className='splash-container'>
      <h2 className="splash-h2">Please reach out to your Customer Success Manager in order to enable the FetchGoat-Now functionality.</h2>
    </div>
  );
};

export default Splash;