import React, { useState, useEffect } from "react";
import { Button, Icon, Form } from 'semantic-ui-react';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import ReactDatePicker from 'react-datepicker'
import "../styles/StopList.scss"
import StopsTable from "../components/StopsTable";
import { routeService } from '../services'
import { Loader } from 'semantic-ui-react'
import { RequestPayload } from "../services/ApiClient/types";
import { RouteDetailsModelView } from "../services/RoutesService/types";
import { formatDate } from "../utils/datehelper";

const StopList = () => {
  let { route_id } = useParams();
  const location = useLocation()
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<RouteDetailsModelView>()
  const [loading, setLoading] = useState(true)
  const [selectedDate, setSelectedDate] = useState(location.state === null || undefined ? new Date() : location.state.preSetSelectedDate || location.state.preSetSelectedDateFromCarrier || location.state.preSetSelectedDateFromMapView);

  const getStopData = async () => {
    setLoading(true);
    let query = {
      routeId: route_id,
      pDate: formatDate(selectedDate.toLocaleDateString())
    };

    let payload: RequestPayload<null> = { queryParams: query, payload: null };
    await routeService.GetStopList(payload)
      .then(response => {
        if (response.success) {
          setTableData(response.result)
          setLoading(false);
        }
        else {
          console.log('error')
          setLoading(false);
        }
      })
  }

  useEffect(() => {
    if (localStorage.token === null || localStorage.token === undefined) {
      const navTo = `/`
      navigate(navTo, {
        state: {
          preSetSelectedDate: selectedDate
        }
      })
    }
    else {
      let mm: number | string = parseInt(selectedDate.getMonth()) + 1; // Months start at 0!
      let dd: number | string = parseInt(selectedDate.getDate());
      if (dd < 10) dd = '0' + dd;
      if (mm < 10) mm = '0' + mm;
    }
    getStopData();

    // eslint-disable-next-line
  }, [selectedDate, navigate, route_id]);

  const handleNavigate = (route: string) => {
    const navTo = `/${route}`
    navigate(navTo, {
      state: {
        preSetSelectedDate: selectedDate
      }
    })
  }

  return (
    <div className="background-color">
      <div className='stopList-container'>
        <div className="buttons-container">
          <Button.Group size='big'>
            <Button id="btn-carrier-view" onClick={() => handleNavigate('Routed')}>
              <Icon name="truck" />Carrier View
            </Button>
            <Button id="btn-routes-view" onClick={() => handleNavigate('Dashboard')}>
              <Icon name="road" />Routes View
            </Button>
            <Button id="btn-map-view" onClick={() => handleNavigate('Map')}>
              <Icon name="map" />Map View
            </Button>
          </Button.Group>
        </div>
        <Form>
          <Form.Group>
            <p className="route_id">Route ID: <b>{tableData != null && tableData.routeIdExt != null ? tableData.routeIdExt : ""}</b></p>
            <div id="date-picker">
              <ReactDatePicker selected={selectedDate} onChange={(date) => setSelectedDate(date)} />
            </div>
          </Form.Group>
        </Form>
        {loading ? <div><Loader active size='massive' id="loader">Loading</Loader></div>
          :
          <StopsTable tableData={tableData} />
        }
      </div>
    </div>
  )
}

export default StopList;