import { apiClient } from '../ApiClient';
import { SearchView } from "./types"
import { ApiResponse, RequestPayload } from "../ApiClient/types"

class SearchService {
  /** 
   * Returns the search info for a particular route stop or order
   * @param payload = RequestPayload<null>
   * @param queryParams =  - string
   * @param queryParams.searchCriteria - string
   * @param queryParams.fromDate - string (optional)
   * @param queryParams.toDate - string (optional)
   * @example
   * ```ts
   * let query: SearchQueryParams = {
   *   searchCriteria: '123 Main Street',
   *   fromDate: '',
   *   toDate: ''   * 
   * };
   * let payload: RequestPayload<null> = {  queryParams: query, payload: null  };
   * ```
   * 
   * @returns SearchView
   */
  async GetTrackingInfo(payload: RequestPayload<null>): Promise<ApiResponse<SearchView>> {
    return apiClient.get<SearchView> (
      '/search/',
      payload
    );
  }

}

export { SearchService }