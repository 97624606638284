
import { ResetPasswordRequest } from "../services/AccountService/types";
import { accountService } from "../services";
import { RequestPayload } from "../services/ApiClient/types";
import { useState } from "react";
import { Message, Form, Button, Loader, Segment, Dimmer, Image } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";

interface PasswordConfirmationProps {
    resetToken: string
}

const PasswordConfirmation: React.FC<PasswordConfirmationProps> = (props) => {

    const token = props.resetToken;
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [pwReset, setPwReset] = useState(false);
    const [visible, setVisible] = useState(true);
    const [pwError, setPWError] = useState(false);
    const [isLoading] = useState(false);
    const navigate = useNavigate();

    // Function to handle password change
    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let _password = e.target.value;
        setPassword(_password);
        setError('');

    }

    // Function to handle confirm password change
    const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let _confirmPassword = e.target.value;
        setConfirmPassword(_confirmPassword);
        setError('');
    };

    // Function to handle saving password to API
    const saveNewPassword = (token: string, password: string, confirmpassword: string) => {
        setError('')
        setPWError(false)
        setVisible(true)

        let request: ResetPasswordRequest = {
            token,
            password,
            confirmpassword,
        };
        let payload: RequestPayload<ResetPasswordRequest> = { payload: request };
        if (password === confirmpassword) {
            accountService.resetPassword(payload).then(res => {
                if (res.success) {
                    setPwReset(true)
                    setTimeout(() => {
                        navigate('/')
                    }, 2000);
                } else {
                    console.log(res.message)
                }
            })
        }
        else {
            setPWError(true);
        }
    }

    // This function dismisses the messages that are being used
    const handleDismiss = () => {
        setVisible(false);
        setPWError(false);
    };

    const PasswordMessage = () => <Message color="blue" header='Password Requirements' content={`The password must have at least one lower-case letter, one upper-case letter, one digit, one special character and length between 6 to 100.`} />
    const SuccessMessage = () => <Message success header='Success' content={`Your new password has been saved. Navigating back to login page!`} onDismiss={handleDismiss} />
    const MismatchMessage = () => <Message error header='Error' content={`Passwords do not match.`} onDismiss={handleDismiss} />

    return (
        <>
            <h2 className='resetPass-header'>Change Password</h2>
            <PasswordMessage />
            {pwReset && visible && <SuccessMessage />}
            {pwError && visible && <MismatchMessage />}
            <Form>
                <div>
                    <label>Password:</label>
                    <Form.Input
                        type="password"
                        value={password}
                        onChange={handlePasswordChange}
                        required
                        style={{ marginBottom: "15px" }}
                    />
                </div>
                <div>
                    <label>Confirm Password:</label>
                    <Form.Input
                        type="password"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        required
                    />
                </div>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                <Button 
                    className="resetPass-signIn" 
                    style={{ marginTop: "15px" }} 
                    onClick={() => navigate('/')}>
                        Click here to sign in
                </Button>
                <Button
                    style={{ marginTop: "15px" }}
                    className="resetPass-button"
                    color="blue"
                    disabled={isLoading}
                    onClick={() => saveNewPassword(token, password, confirmPassword)}
                >
                    {
                        !isLoading ? 'Change Password' : 
                        
                        <Segment>
                            <Dimmer active>
                                <Loader size='small'>Loading</Loader>
                            </Dimmer>

                            <Image src='/images/wireframe/short-paragraph.png' />
                        </Segment>
                    }
                </Button>
            </Form>
        </>
    )
}

export default PasswordConfirmation;
