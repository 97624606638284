import { Card, Button, Table, Dropdown, Icon, Checkbox, Form, Input, List } from 'semantic-ui-react';
import "../styles/Preferences.scss"
import { useEffect, useState, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { preferencesService } from '../services';
import { UserPerformance, defaultUserPerformance } from '../services/PreferencesService/types';
import { RequestPayload } from '../services/ApiClient/types';
import { useUser } from '../components/AppProvider';
import { User, UserPreference } from '../services/types'

const Preferences = () => {
  const navigate = useNavigate();
  const { user, setUser } = useUser();
  const [isLoading, setIsLoading] = useState(true);
  const [newEmail, setNewEmail] = useState('')
  const [notificationExpts, setNotificationExpts] = useState([])
  const [preferenceData, setPreferenceData] = useState<User>({ userName: "", userPreferences: [], userRestrictions: null, applicationFeatures: [], id: 0 });
  const [emailData, setEmailData] = useState([])
  const [notifyArr, setNotifyArr] = useState('')
  const [emailArr, setEmailArr] = useState('')
  const [performances, setPerformances] = useState<UserPerformance>(defaultUserPerformance)

  const ACTIONS = {
    CALL_API: 'call-api',
    SUCCESS: 'success',
    ERROR: 'error',
    UPDATE_LANDING_PAGE: 'Update LandingPage',
    UPDATE_1ST_EXCEPTION: 'Update 1st Exc',
    UPDATE_2ND_EXCEPTION: 'Update 2st Exc',
    UPDATE_NEUTRAL_PERFORMANCE: 'Update NeutralPre',
    UPDATE_POS_PERFORMANCE: 'Update PosPre',
    UPDATE_NEG_PERFORMANCE: 'Update NegPre',
    UPDATE_EMAIL: 'Update Address',
    UPDATE_NOTIFICATIONS: 'Update Notifications',
    UPDATE_PERFORMANCE: 'Update Performance'
  };

  const userDetailsReducer = (state: any, action: any) => {

    switch (action.type) {
      case ACTIONS.CALL_API: {
        // console.log('user: ', user);
        return {
          ...state,
          loading: true,
        };
      }
      case ACTIONS.SUCCESS: {
        const { data } = action
        setPerformances({
          setPref: data.userPreferences.find((x: UserPreference) => x.key === "default-landing-page").value,
          firstExc: data.userPreferences.find((x: UserPreference) => x.key === "ett-late-1").value,
          secondExc: data.userPreferences.find((x: UserPreference) => x.key === "ett-late-2").value,
          neuPre: data.userPreferences.find((x: UserPreference) => x.key === "ott-neutral").value,
          posPre: data.userPreferences.find((x: UserPreference) => x.key === "ott-positive").value,
          negPre: data.userPreferences.find((x: UserPreference) => x.key === "ott-negative").value
        })
        setEmailData(data.userPreferences.find((x: any) => x.key === "EmailNotifications-Emails").value.split(','))
        setPreferenceData(data);
        setNotificationExpts(data.userPreferences.find((x: UserPreference) => x.key === "EmailNotifications-Events").value.split(','))
        setIsLoading(false)
        return {
          ...state,
          loading: false,
          userDetails: action.data,
        };
      }
      case ACTIONS.ERROR: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }
      case ACTIONS.UPDATE_LANDING_PAGE: {
        setPerformances(
          { ...performances, setPref: action.payload }
        )
        return {
          ...state,
          userDetails: {
            ...state.userDetails,
            userPreferences: state.userDetails.userPreferences.map((pref: any) => {
              if (pref.key === "default-landing-page") {
                return { ...pref, value: action.payload }
              }
              return pref;
            })
          }
        }
      }
      case ACTIONS.UPDATE_1ST_EXCEPTION: {
        setPerformances(
          { ...performances, firstExc: action.payload }
        )
        return {
          ...state,
          userDetails: {
            ...state.userDetails,
            userPreferences: state.userDetails.userPreferences.map((pref: any) => {
              if (pref.key === "ett-late-1") {
                return { ...pref, value: action.payload }
              }
              return pref;
            })
          }
        }
      }
      case ACTIONS.UPDATE_2ND_EXCEPTION: {
        setPerformances(
          { ...performances, secondExc: action.payload }
        )
        return {
          ...state,
          userDetails: {
            ...state.userDetails,
            userPreferences: state.userDetails.userPreferences.map((pref: any) => {
              if (pref.key === "ett-late-2") {
                return { ...pref, value: action.payload }
              }
              return pref;
            })
          }
        }
      }
      case ACTIONS.UPDATE_NEUTRAL_PERFORMANCE: {
        setPerformances(
          { ...performances, neuPre: action.payload }
        )
        return {
          ...state,
          userDetails: {
            ...state.userDetails,
            userPreferences: state.userDetails.userPreferences.map((pref: any) => {
              if (pref.key === "ott-neutral") {
                return { ...pref, value: action.payload }
              }
              return pref;
            })
          }
        }
      }
      case ACTIONS.UPDATE_POS_PERFORMANCE: {
        setPerformances(
          { ...performances, posPre: action.payload }
        )
        return {
          ...state,
          userDetails: {
            ...state.userDetails,
            userPreferences: state.userDetails.userPreferences.map((pref: any) => {
              if (pref.key === "ott-positive") {
                return { ...pref, value: action.payload }
              }
              return pref;
            })
          }
        }
      }
      case ACTIONS.UPDATE_NEG_PERFORMANCE: {
        setPerformances(
          { ...performances, negPre: action.payload }
        )
        return {
          ...state,
          userDetails: {
            ...state.userDetails,
            userPreferences: state.userDetails.userPreferences.map((pref: any) => {
              if (pref.key === "ott-negative") {
                return { ...pref, value: action.payload }
              }
              return pref;
            })
          }
        }
      }
      case ACTIONS.UPDATE_EMAIL: {
        return {
          ...state,
          userDetails: {
            ...state.userDetails,
            userPreferences: state.userDetails.userPreferences.map((pref: any) => {
              if (pref.key === "EmailNotifications-Emails") {
                return { ...pref, value: action.payload }
              }
              return pref;
            })
          }
        }
      }
      case ACTIONS.UPDATE_NOTIFICATIONS: {
        const p = action.data;
        return {
          ...state,
          userDetails: {
            ...state.userDetails,
            userPreferences: state.userDetails.userPreferences.map((pref: any) => {
              if (pref.key === "EmailNotifications-Events") {
                let prefValArr = pref.value.split(',');
                p.checked ?
                  !prefValArr.includes(p.value) && prefValArr.push(p.value)
                  :
                  prefValArr = prefValArr.filter((pVal: any) => pVal !== p.value);
                prefValArr = prefValArr.filter((blankValue: string) => blankValue !== "");
                let finalVal = prefValArr.join(',');
                setNotifyArr(prefValArr)
                return { ...pref, value: finalVal }
              }
              return pref;
            })
          }
        }
      }
      case ACTIONS.UPDATE_PERFORMANCE: {
        return
      }
    }
  };
  const initialState: any = {
    userDetails: '',
    loading: false,
    error: null,
  };
  const [state, dispatcher] = useReducer(userDetailsReducer, initialState);
  useEffect(() => {
    let payload: RequestPayload<null> = { payload: null };
    dispatcher({ type: ACTIONS.CALL_API });
    const getPreferences = async () => {
      await preferencesService.GetUserPreferences(payload)
        .then(response => {
          if (response.success) {
            dispatcher({ type: ACTIONS.SUCCESS, data: response.result });
            return;
          }
          else {
            console.error('Error pulling back preference data', response.message);
            setIsLoading(false);
          }
        });
    };

    getPreferences();
  }, [ACTIONS.CALL_API, ACTIONS.SUCCESS]);
  useEffect(() => {
    setNotifyArr(notifyArr)
    setEmailArr(emailArr)
  },
    [notificationExpts, emailData, notifyArr, emailArr]);
  const changedValue = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    let payload: RequestPayload<UserPreference[]> = { payload: state.userDetails.userPreferences };

    preferencesService.UpdateUserPreferences(payload)
      .then(response => {
        if (response.success) {
          setPreferenceData(response.result);
          setIsLoading(false);
          // save user to react context
          let updatedUser: User = {
            userName: response.result.userName,
            userPreferences: response.result.userPreferences,
            userRestrictions: response.result.userRestrictions,
            applicationFeatures: response.result.applicationFeatures
          }
          setUser(updatedUser);
        }
        else {
          console.error("Error updating preferences", response.message);
          setIsLoading(false);
        }
      })
  }

  useEffect(() => {
    if (localStorage.token === null || localStorage.token === undefined) {
      const navTo = `/`
      navigate(navTo)
    }
    else {
      localStorage.setItem('route', "Preferences")
      try {
        let payload: RequestPayload<null> = { payload: null };
        dispatcher({ type: ACTIONS.CALL_API });
        const getPreferences = async () => {
          await preferencesService.GetUserPreferences(payload)
            .then(response => {
              if (response.success) {
                dispatcher({ type: ACTIONS.SUCCESS, data: response.result });
                return;
              }
              else {
                console.error('Error pulling back preference data', response.message);
                setIsLoading(false);
              }
            });
        };
        getPreferences();
      } catch (e) {
        console.log(e)
      }
    }
  }, [navigate, ACTIONS.CALL_API, ACTIONS.SUCCESS]);

  useEffect(() => {

  }, []);



  const style = {
    exceptionThird: {
      border: "2px solid #f5f5f5",
      padding: "8%",
      width: "100%",
      backgroundColor: "#FCC2C2",
      margin: "0%"
    }
  }
  const menuArr = ['Analytics', 'Routed', 'FetchGoatNow', 'Logs', 'Preferences', 'Reports', 'Support', 'Tracking', 'RouteStopTemplate']
  const timeZones = ['default']
  let landingPageOpt: any = []
  let timeZoneOpt: any = []
  menuArr.map((item, idx) => (landingPageOpt.push({ key: idx, value: item, text: item })))
  timeZones.map((item, idx) => timeZoneOpt.push({ key: idx, value: item, text: item }))

  const notificationCheck = (e: any, data: any) => {
    e.persist()
    dispatcher({ type: ACTIONS.UPDATE_NOTIFICATIONS, data: data });
  }

  const removeEmail = (e: any, data: any) => {
    setEmailData(prevEmailData => {
      const newEmailData = prevEmailData.filter((email: any) => email !== data.value);
      dispatcher({ type: ACTIONS.UPDATE_EMAIL, payload: newEmailData.toString() });
      return newEmailData;
    });
  };

  const handleNewEmail = () => {
    let validEmail = emailRegex.test(newEmail)
    if (validEmail === true && !emailData.includes(newEmail)) {
      let newEmailArr = [...emailData, newEmail]
      setEmailData(newEmailArr)
      dispatcher({ type: ACTIONS.UPDATE_EMAIL, payload: newEmailArr.join(',') })
    }
  }

  const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  return (
    <div className="preferences-container">
      <div className="preferences-box">
        <h1 className="preferences-header"> Preferences </h1>
        {(() => {
          if (isLoading) {
            return (<div><p>Loading data...</p></div>)
          } else {
            const { value } = state.userDetails.userPreferences.find((x: any) => x.key === "EmailNotifications-Events")
            return (
              <div>
                <Button onClick={(e: any) => changedValue(e)} id="preferences-button">Save Changes</Button>
                {/* Card 1 */}
                <Card id="account-card">
                  <Card.Content id="account-card-content" header="My Account Information" />
                  <Card.Content id="account-info">
                    <Table>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell id="account-table-cell">
                            <Card.Content>Username</Card.Content>
                          </Table.Cell>
                          <Table.Cell>
                            <Card.Content>{state.userDetails.userName}</Card.Content>
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell id="account-table-cell">
                            <Card.Content>Access Limitations</Card.Content>
                          </Table.Cell>
                          <Table.Cell>
                            {state.userDetails.userRestrictions
                              && state.userDetails.userRestrictions.restrictions
                              && state.userDetails.userRestrictions.restrictions.length > 0
                              ? <Card.Content>{state.userDetails.userRestrictions.restrictionType} - {preferenceData.userRestrictions.restrictions.join(', ')}</Card.Content>
                              : <Card.Content>No restrictions</Card.Content>
                            }
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </Card.Content>
                </Card>

                {/* Card 2 */}
                <Card id="portal-card">
                  <Card.Content id="portal-card-content" header="Portal Settings" />
                  <Card.Content id="portal-title">
                    Default Landing Page
                  </Card.Content>
                  <Card.Content id="portal-content">
                    <Dropdown value={performances.setPref} id="portal-dropdown" options={landingPageOpt} onChange={(e, data) => dispatcher({ type: ACTIONS.UPDATE_LANDING_PAGE, payload: data.value })}>
                    </Dropdown>
                  </Card.Content>
                  <Card.Content id="portal-title">
                    My Time Zone
                  </Card.Content>
                  <Card.Content id="portal-content">
                    <Dropdown placeholder='default' id="portal-dropdown" options={timeZoneOpt} />
                  </Card.Content>
                </Card>

                {/* Card 3 */}
                <Card id="exception-card">
                  <Card.Content style={{ backgroundColor: "#f5f5f5" }} header="Exception Time Thresholds" />
                  <Card.Content id="account-info">
                    <Table className="preferences-account">
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell id="exception-table-cell">
                            <Card.Content id="exception-first">First Late Exception</Card.Content>
                            <Card.Content id="exception-second">Second Late Exception</Card.Content>
                            <Card.Content style={style.exceptionThird}>Third Late Exception</Card.Content>
                          </Table.Cell>
                          <Table.Cell id="exception-table-cell">
                            <Card.Content id="exception-content">
                              <label>Less than </label>
                              <input id="exception-input" placeholder={performances.firstExc} type="number" min="0" max="60" step="1" className="form-control" onBlur={(e) => dispatcher({ type: ACTIONS.UPDATE_1ST_EXCEPTION, payload: e.target.value })} />
                              <label> minute(s)</label>
                            </Card.Content>
                            <Card.Content id="exception-content">
                              <label>Between </label>
                              <input id="exception-input" disabled placeholder={(parseInt(performances.firstExc) + 1).toString()} type="number" step="1" className="form-control" />
                              <label> and </label>
                              <input id="exception-input" placeholder={performances.secondExc} type="number" min={parseInt(performances.firstExc) + 1} max="120" step="1" className="form-control" onBlur={(e) => dispatcher({ type: ACTIONS.UPDATE_2ND_EXCEPTION, payload: e.target.value })} />
                              <label> minutes</label>
                            </Card.Content>
                            <Card.Content id="exception-content">
                              <label>More than </label>
                              <input id="exception-input" disabled placeholder='120' type="number" min="0" step="1" className="form-control" />
                              <label> minute(s)</label>
                            </Card.Content>
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </Card.Content>
                </Card>

                {/* CARD 4 */}
                <Card id="onTime-card">
                  <Card.Content style={{ backgroundColor: "#f5f5f5" }} id="onTime-header">
                    On-time Trending <Icon name="question circle" />
                  </Card.Content>
                  <Card.Content id="account-info">
                    <Table className="preferences-account">
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell id="onTime-table-cell">
                            <Card.Content id="onTime-first">Neutral Performance <span id="first-span">(±%)</span></Card.Content>
                            <Card.Content id="onTime-second">Positive Performance <span id="second-span">(+%)</span></Card.Content>
                            <Card.Content id="onTime-third">Negative Performance <span id="third-span">(-%)</span></Card.Content>
                          </Table.Cell>
                          <Table.Cell id="onTime-table-cell">
                            <Card.Content id="onTime-content">
                              <label><Icon name="minus" id="neutral-icon" />  Between </label>
                              <input
                                id="onTime-input"
                                disabled
                                placeholder='0%'
                                type="number"
                                min="0"
                                step="1"
                                className="form-control" />
                              <label> and </label>
                              <input
                                id="onTime-input"
                                placeholder={performances.neuPre + "%"}
                                type="number" min="0" step="1"
                                className="form-control"
                                onBlur={(e) => dispatcher({ type: ACTIONS.UPDATE_NEUTRAL_PERFORMANCE, payload: e.target.value })} />
                            </Card.Content>
                            <Card.Content id="onTime-content">
                              <div id="section">
                                <label><Icon name="angle up" id="positive-icon" /> Between </label>
                                <input
                                  id="onTime-input"
                                  disabled
                                  placeholder={(parseInt(performances.neuPre) + 1).toString() + "%"}
                                  type="number" min={(parseInt(performances.neuPre) + 1).toString() + "%"}
                                  step="1"
                                  className="form-control" />
                                <label> and </label>
                                <input
                                  id="onTime-input"
                                  placeholder={performances.posPre + "%"}
                                  type="number" min={(parseInt(performances.neuPre) + 2).toString()}
                                  step="1"
                                  className="form-control"
                                  onBlur={(e) => dispatcher({ type: ACTIONS.UPDATE_POS_PERFORMANCE, payload: e.target.value })} />
                              </div>
                              <div id="section">
                                <label><Icon name="angle double up" id="positive-icon" /> More than </label>
                                <input
                                  id="onTime-input"
                                  disabled placeholder={(parseInt(performances.posPre) + 1).toString() + '%'}
                                  type="number"
                                  min={(parseInt(performances.neuPre) + 3).toString()}
                                  step="1"
                                  className="form-control" />
                              </div>
                            </Card.Content>
                            <Card.Content id="onTime-content">
                              <div id="section">
                                <label><Icon name="angle down" id="negative-icon" /> Between </label>
                                <input id="onTime-input"
                                  disabled
                                  placeholder={(parseInt(performances.neuPre) + 1).toString() + "%"}
                                  type="number"
                                  min="0"
                                  step="1"
                                  className="form-control" />
                                <label> and </label>
                                <input
                                  id="onTime-input"
                                  placeholder={performances.negPre + "%"}
                                  type="number" min={(parseInt(performances.neuPre) + 2).toString()}
                                  step="1"
                                  className="form-control"
                                  onBlur={(e) => dispatcher({ type: ACTIONS.UPDATE_NEG_PERFORMANCE, payload: e.target.value })} />
                              </div>
                              <div id="section">
                                <label><Icon name="angle double down" id="negative-icon" /> More than </label>
                                <input
                                  id="onTime-input"
                                  disabled
                                  placeholder={(parseInt(performances.negPre) + 1).toString() + '%'}
                                  type="number"
                                  min="0"
                                  step="1"
                                  className="form-control" />
                              </div>
                            </Card.Content>
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </Card.Content>
                </Card>

                {/* CARD 5 */}
                <Card id="exception-card">
                  <Card.Content style={{ backgroundColor: "#f5f5f5" }} header="Email Notification Settings" />
                  <Card.Content>
                    <Table>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>
                            Notification events
                          </Table.Cell>
                          <Table.Cell>
                            <Card.Content id="late"> <Checkbox checked={value.includes('late')} value={'late'} onChange={(e, data) => notificationCheck(e, data)} /> Late Exceptions</Card.Content>
                            <Card.Content id="missed-stop"> <Checkbox checked={value.includes('missed-stop')} value={'missed-stop'} onChange={(e, data) => notificationCheck(e, data)} /> Missed Stop Exceptions</Card.Content>
                            <Card.Content id="out-of-sequence"><Checkbox checked={value.includes('out-of-sequence')} value={'out-of-sequence'} onChange={(e, data) => notificationCheck(e, data)} /> Out of Sequence Exceptions</Card.Content>
                            <Card.Content id="missing-POD"><Checkbox checked={value.includes('missing-pod')} value={'missing-pod'} onChange={(e, data) => notificationCheck(e, data)} /> Missing POD Exceptions</Card.Content>
                            <Card.Content id="carrier-specific"><Checkbox checked={value.includes('real')} value={'real'} onChange={(e, data) => notificationCheck(e, data)} /> Carrier Specific Exceptions</Card.Content>
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>
                            <Form>
                              {newEmail.length > 6 && !emailRegex.test(newEmail) && <p style={{ color: 'red' }}><sub>Please enter a valid email address</sub></p>}
                              <Input
                                labelPosition='left corner'
                                action={{
                                  disabled: newEmail.length < 7 ? true : false,
                                  icon: 'plus',
                                  color: newEmail.length < 7 ? 'black' : emailRegex.test(newEmail) ? "green" : "red",
                                  onClick: () => handleNewEmail(),
                                }}
                                defaultValue={newEmail}
                                onChange={(e: any) => setNewEmail(e.target.value)}
                                placeholder="New Email Address"
                              />
                              <br />
                            </Form>
                          </Table.Cell>
                          <Table.Cell>
                            <Form>
                              <List>
                                {emailData.map((email: string, idx: number) =>
                                  <List.Item icon="minus" onClick={(e: any, data: any) => removeEmail(e, data)} style={{ cursor: "pointer", width: '1rem' }} value={email} key={idx} content={email} />
                                )}
                              </List>
                            </Form>
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </Card.Content>
                </Card>
                <Button onClick={(e) => changedValue(e)} id="preferences-button">Save Changes</Button>
              </div>
            )
          }
        })()}
      </div>
    </div>
  )

}
export default Preferences
